/*
Breakpoints
*/
:root {
    /*
    Colors
    */
    --color-reaction:       #7C17FF;
    --color-white:          #FFFFFF;
    --color-whiteIsh:       #F9F9F9;
    --color-grey:           #757575;
    --color-otherGrey:      #333333;
    --color-greyBackground: #EBEBEB;
    --color-greyOutline:    #DDDDDD;
    --color-dark:           #222222;
    --color-light:          rgba(231, 231, 231, 0.3);
    --color-ultraLight:     rgba(204, 204, 204, 0.15);
    --color-darkReaction:   #6312CC;
}
/*
 Font faces
*/
@font-face {
    font-family: 'Basis Grotesque Ia';
    src: url('assets/fonts/basis-grotesque-regular.eot');
    src: url('assets/fonts/basis-grotesque-regular.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/basis-grotesque-regular.woff') format('woff'),
         url('assets/fonts/basis-grotesque-regular.woff2') format('woff2'),
         url('assets/fonts/basis-grotesque-regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Basis Grotesque Bold Ia';
    src: url('assets/fonts/basis-grotesque-bold-pro.eot');
    src: url('assets/fonts/basis-grotesque-bold-pro.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/basis-grotesque-bold-pro.woff') format('woff'),
         url('assets/fonts/basis-grotesque-bold-pro.woff2') format('woff2'),
         url('assets/fonts/basis-grotesque-bold-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Basis Grotesque Ia';
    src: url('assets/fonts/basis-grotesque-italic.eot');
    src: url('assets/fonts/basis-grotesque-italic.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/basis-grotesque-italic.woff') format('woff'),
         url('assets/fonts/basis-grotesque-italic.woff2') format('woff2'),
         url('assets/fonts/basis-grotesque-italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Basis Grotesque Ia';
    src: url('assets/fonts/basis-grotesque-medium.eot');
    src: url('assets/fonts/basis-grotesque-medium.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/basis-grotesque-medium.woff') format('woff'),
         url('assets/fonts/basis-grotesque-medium.woff2') format('woff2'),
         url('assets/fonts/basis-grotesque-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Suisse Works Ia';
    src: url('assets/fonts/SuisseWorks-Bold-WebS.eot');
    src: url('assets/fonts/SuisseWorks-Bold-WebS.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/SuisseWorks-Bold-WebS.woff') format('woff'),
         url('assets/fonts/SuisseWorks-Bold-WebS.woff2') format('woff2'),
         url('assets/fonts/SuisseWorks-Bold-WebS.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
/*
 Mixins
*/
/* Normal Font 12*/
/* Normal Font 14*/
/* Normal Font 15*/
/* Normal Font 16*/
/* Normal Font 18*/
/* Normal Font 20*/
/* Normal Font 24*/
/* Normal Font 27*/
/* Normal Font 32*/
/* Bold Font 12*/
/* Bold Font 14*/
/* Bold Font 15*/
/* Bold Font 16*/
/* Bold Font 18*/
/* Bold Font 20*/
/* Bold Font 24*/
/* Bold Font 27*/
/* Bold Font 32*/
/*
 General Typography Styles
*/
body {
    font-family: 'Basis Grotesque Ia', sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
strong {
    font-family: 'Basis Grotesque Ia', sans-serif;
    font-weight: 500;
}
h1 {
    font-family: 'Basis Grotesque Bold Ia', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
}
h2 {
    font-family: 'Basis Grotesque Bold Ia', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
}
h3 {
    font-family: 'Basis Grotesque Bold Ia', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}
li,
p {
    font-family: 'Basis Grotesque Ia', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
}
.accordion {
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
    border-top: 1px solid rgba(0, 0, 0, 0.18);
    list-style: none;
    margin: 0;
    padding: 0;
}
.accordion .accordion-item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.18);
        cursor: pointer;
        padding: 15px 0 13px;
    }
.accordion .accordion-item:last-child {
            border-bottom: none;
        }
.accordion .accordion-item-toggle {
        transition: transform 0.2s ease-in;
    }
.accordion .accordion-item-toggle.is-open {
            transform: rotate(180deg);
        }
.accordion .accordion-item-header {
        align-items: center;
        background: none;
        border: 0;
        cursor: pointer;
        display: flex;
        padding: 0;
        width: 100%;
    }
.accordion .accordion-item-icon {
        margin-right: 10px;
        width: 24px;
    }
.accordion .accordion-item-icon img {
            max-width: 100%;
        }
.accordion .accordion-item-label {
        color: var(--color-dark);
        flex: 2 1;
        font-size: 16px;
        font-weight: bold; /* to be revised once the fonts are set */
        margin-right: 10px;
        text-align: left;
    }
.accordion .accordion-item-content {
        margin-top: 10px;
    }
.accordion .accordion-item-content > div {
            max-height: 100vh;
            overflow: hidden;
            transition: max-height 0.75s ease-out;
        }
.accordion .accordion-item-content:not(.visible) > div {
            max-height: 0;
            margin: 0;
            padding: 0;
            transition: max-height 0.25s ease-out;
        }
.btn {
    align-items: center;
    cursor: pointer;
    background: var(--color-white); 
    border: 1px solid var(--color-reaction);
    border-radius: 32px;
    color: var(--color-reaction);
    display: inline-flex;
    margin-right: 8px;
    padding: 5px 15px 7px;
    text-decoration: none;

    font-family: 'Basis Grotesque Ia', sans-serif;

    font-style: normal;

    font-weight: 500;

    font-size: 16px;

    line-height: 22px;
}
.btn svg {
        margin-right: 8px;
    }
.btn:hover,
    .btn:focus {
        background: var(--color-reaction);
        color: var(--color-white);
    }
.btn:hover svg path, .btn:focus svg path {
            stroke: var(--color-white);
        }
.btn.primary {
        background: var(--color-reaction);
        color: var(--color-white);
    }
.btn.primary:hover,
        .btn.primary:focus {
            background: initial;
            color: var(--color-reaction);
        }
.btn.primary:hover svg path, .btn.primary:focus svg path {
                stroke: var(--color-reaction);
            }
.checkbox {
    width: 24px;
}
.contact-card {
    border: 1px solid var(--color-greyBackground);
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
    padding: 20px;
    position: relative;
}
@media (min-width: 768px) {
.contact-card {
        margin-top: 30px;
        margin-bottom: 20px;
        padding-top: 36px;
        padding-bottom: 36px;
        align-items: center
}
    }
@media (max-width: 766px) {
.contact-card {
        margin: 20px 10px 20px 10px
}
    }
.contact-card .contact-card-image {
        flex: 0 1 auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
.contact-card .contact-card-image img {
            height: 100%;
            max-height: 64px;
        }
.contact-card .contact-card-image.small {
            height: 60px;
            margin-left: 10px;
        }
.contact-card .contact-card-image.small img {
                max-height: 60px;
            }
@media (min-width: 768px) {
.contact-card .contact-card-image {
            height: 125px;
            margin-left: 50px;
            margin-right: 20px
    }

            .contact-card .contact-card-image img {
                max-height: 125px;
            }
        }
.contact-card .contact-card-info {
        display: flex;
        flex: 3 1;
        flex-direction: column;
        margin-left: 15px;
    }
.contact-card .contact-card-info h3 {            
            font-family: 'Basis Grotesque Ia', sans-serif;            
            font-style: normal;            
            font-weight: 500;            
            font-size: 16px;            
            line-height: 22px;
        }
.contact-card .contact-card-info span:nth-child(n + 2) {            
            font-family: 'Basis Grotesque Ia', sans-serif;            
            font-style: normal;            
            font-weight: 500;            
            font-size: 14px;            
            line-height: 18px;
        }
.contact-card .contact-card-info a,
        .contact-card .contact-card-info span:nth-child(3) {
            color: var(--color-reaction);
            text-decoration: underline;
        }
.contact-card .contact-card-icon {
        position: absolute;
        right: 30px;
        top: 30px;
    }
@media (max-width: 941px) {
.contact-card .contact-card-icon {
            display: none
    }
        }
.contact-card .contact-card-icon.small {
            display: none;
        }
.courses-header {
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
@media (min-width: 768px) {
.courses-header {
        background-color: var(--color-ultraLight);
        flex-direction: row
}

        .courses-header > div {
            flex: 1 1;
        }
        
        .courses-header:hover{
            background-color: var(--color-reaction);
        }

            .courses-header:hover a,
            .courses-header:hover p,
            .courses-header:hover h2 {
                color: var(--color-white);
            }

            .courses-header:hover h2{
                text-decoration: underline;
            }

            .courses-header:hover svg path{
                fill: var(--color-white);
            }
    }
@media (max-width: 766px) {
.courses-header {
        border-radius: 20px;
        padding: 0 20px;
        width: 100%
}
    }
.courses-header-image {
    flex: 1 1;
    width: 100%;
}
.courses-header-image img {
        width: 100%;
    }
@media (min-width: 768px) {
.courses-header-image {
        height: 405px;
        overflow: hidden
}

        .courses-header-image img {
            height: 100%;
            width: auto;
        }
    }
@media (max-width: 766px) {
.courses-header-image {
        border-radius: 20px 20px 0 0;
        flex: 0 0 auto;
        height: 150px;
        overflow-y: hidden;
        width: 100%
}
        
        .courses-header-image img {
            height: initial;
            position: relative;
            top: -25%;
            width: 100%;
        }
    }
.courses-header-text {
    flex: 1 1;
    padding-top: 25px;
    padding-left: 5px;
    text-decoration: none;
}
.courses-header-text .arrow {
        display: none;
    }
.courses-header-text > span {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-right: 25px;
    }
.courses-header-text a,
    .courses-header-text h2 {
        text-decoration-line: underline;
        color: var(--color-white);

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.courses-header-text p {
        color: var(--color-white);
        max-width: 250px;
        margin-bottom: 40px;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
@media (min-width: 768px) {
.courses-header-text {
        padding-top: 68px
}

        .courses-header-text .arrow {
            display: initial;
        }

        .courses-header-text .whiteArrow {
            display: none;
        }

        .courses-header-text a,
        .courses-header-text h2 {
            color: var(--color-reaction);
            text-decoration-line: none;
            
            font-family: 'Basis Grotesque Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: 500;
            
            font-size: 24px;
            
            line-height: 30px;
        }

        .courses-header-text p {
            color: var(--color-dark);;
            margin-bottom: 90px;
            min-height: 175px;
            padding-left: 75px;
        }

        .courses-header-text > span {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding-right: 50px;
            padding-left: 75px;
            width: 100%;
        }
    }
@media (max-width: 766px) {
.courses-header-text {
        background-color: var(--color-reaction);
        border-radius: 0 0 20px 20px;
        padding: 20px 0  0 30px
}
    }
.courses-tags {
    display: inline-flex;
    margin-top: 30px;
    margin-left: -20px;
    overflow-y: hidden;
    width: calc(100% + 35px);
    -ms-overflow-style: none;
}
/* TODO: No firefox support */
.courses-tags::-webkit-scrollbar {
        display: none;
    }
.courses-tags button {
        cursor: pointer;
        flex: 0 0 auto;
    }
.courses-tags button:first-child {
            margin-left: 30px;
        }
.courses-tags button:last-child {
            margin-right: 30px;
        }
.courses-all {
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.courses-all > h2 {
        color: var(--color-dark);
        margin-top: 18px;
        margin-left: 10px;
        max-width: 440px;
    }
.courses-all img {
        flex: 0 1 auto;
        width: 100%;
    }
.courses-all .courses-all-list > h2 {
            color: var(--color-reaction);
            margin-left: 10px;
            margin-top: 30px;
        }
.courses-all .course {
        align-items: flex-start;
        align-content: flex-start;
        background-color: rgb(247, 247, 247);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        padding: 20px 15px;
        position: relative;
    }
.courses-all .course a {
            color: var(--color-dark);
            text-decoration: none;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.courses-all .course > h3 {
            font-weight: bold;
            margin-bottom: 5px;
        }
.courses-all .course > div {
            color: var(--color-dark);

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.courses-all .course > span {
            background-color: var(--color-white);
            border-radius: 30px;
            color: var(--color-reaction);
            margin-top: 15px;
            padding: 10px 15px;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
@media (min-width: 768px) {
.courses-all .course {
            padding-bottom: 35px;
            padding-left: 66px;
            padding-top: 40px
    }

            .courses-all .course > h3 a {
                color: var(--color-reaction);
            }
        }
.courses-all .course-date span {
            text-transform: capitalize;
        }
@media (min-width: 768px) {
.courses-all .course-date {
            background-color: var(--color-white);
            border-radius: 10px;
            color: var(--color-dark);
            padding: 6px;
            position: absolute;
            right: 32px;
            top: 38px
    }
        
            .courses-all .course-date span {
                font-weight: 600;
                font-family: 'Basis Grotesque Bold Ia', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
            }
        }
.courses-all .course-ingress {
        margin-bottom: 50px;
        margin-top: 40px;
        position: relative;
    }
.courses-all .course-ingress div,
        .courses-all .course-ingress span {
            margin: 0;
            padding: 0;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.courses-all .course-ingress div {
            background-image: linear-gradient(to bottom,transparent,rgba(247, 247, 247, 45));
            bottom: 0;
            height: 75%;
            left: 0;
            position: absolute;
            width: 100%;
        }
@media (max-width: 766px) {
.courses-all .course-ingress {
            display: none
    }
        }
.courses-all .course-event-tag {
        background-color: var(--color-white);
        border-color: var(--color-white);
        border-radius: 32px;
        border-style: solid;
        border-width: 10px 14px;
        display: inline-block;
    }
.courses-all .course-event-tag a {
            color: var(--color-reaction);
            transition: all .25s

            @mixin normalFont-16;
        }
.courses-all .course-event-tag,
        .courses-all .course-event-tag a {
            transition: none;
        }
.courses-all .course-event-tag:hover a,
            .courses-all .course-event-tag:hover {
                background-color: var(--color-reaction);
                border-color: var(--color-reaction);
                color: var(--color-white);
            }
@media (max-width: 766px) {
.courses-all .course-event-tag {
            margin-top: 20px;
            margin-left: -4px
    }
        }
.courses-all .course-speaker {
        display: inline-block;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.courses-all .course-speaker:nth-last-child(n + 2) {
            margin-right: 10px;
        }
.files-list-wrapper {
    width: 100%;
}
.files-list-wrapper .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
.files-list-wrapper .header h3 {
            color: var(--color-dark);
            
            font-family: 'Basis Grotesque Bold Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: normal;
            
            font-size: 14px;
            
            line-height: 18px;
        }
.files-list-wrapper .header .edit-files {
            background: none;
            border: none;
            cursor: pointer;
            text-decoration: underline;
            -webkit-user-select: none;
                    user-select: none;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 14px;

            line-height: 18px;
        }
.files-list-wrapper .file-list {
        padding: 10px 0;
    }
.files-list-wrapper .file-wrapper {
        background: linear-gradient(
            to right,
            var(--color-white) 50%,
            var(--color-ultraLight) 50%
        );
        background-size: 200% 100%;
        border: 1px solid var(--color-ultraLight);
        border-radius: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        overflow: hidden;
        width: 100%;
    }
.files-list-wrapper .file-wrapper:not(.animate) {
            border: 1px solid var(--color-white);
        }
.files-list-wrapper .file-wrapper span {
            margin-left: 5px;
        }
.files-list-wrapper .file-wrapper.animate {
            transition: all 2s ease;
            border: 1px solid transparent;
        }
.files-list-wrapper .file-wrapper .file-name {
            padding: 12px 20px;
            flex: 6 1;
        }
.files-list-wrapper .file-wrapper .file-name,
            .files-list-wrapper .file-wrapper .file-name a {
                color: var(--color-reaction);
                cursor: pointer;
                text-decoration: none;
    
                font-family: 'Basis Grotesque Ia', sans-serif;
    
                font-style: normal;
    
                font-weight: 500;
    
                font-size: 16px;
    
                line-height: 22px;
            }
.files-list-wrapper .file-wrapper .file-name .bold{
                text-transform: capitalize;
    
                font-family: 'Basis Grotesque Bold Ia', sans-serif;
    
                font-style: normal;
    
                font-weight: normal;
    
                font-size: 16px;
    
                line-height: 22px;
            }
@media (max-width: 941px) {
                .files-list-wrapper .file-wrapper .file-name .noMobile{
                    display: none;
                }
            }
.files-list-wrapper .file-wrapper .add-wrapper {
            cursor: pointer;
            height: 100%;
            padding: 15px 23px;
        }
.files-list-wrapper .file-wrapper .add-wrapper img {
                transform: rotate(45deg);
            }
.files-list-wrapper .file-wrapper .remove-wrapper {
            cursor: pointer;
            height: 100%;
            padding: 15px 23px;
        }
.files-list-wrapper .file-wrapper .arrow-wrapper {
            background-color: var(--color-white);
            height: 100%;
            padding: 15px 23px;
        }
.files-list-wrapper .file-wrapper input[type=file] {
            display: none;
        }
.files-list-wrapper .error {
        color: darkred;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
footer {
    padding: 30px 32px 40px;
    max-width: 1440px;
    margin: 0 auto;
    padding-top: 40px;
}
footer .footer-backlink {
        align-items: center;
        color: #7c17ff;
        display: flex;
        font-size: 14px;
        line-height: 18px;
    }
footer .footer-backlink img {
            margin-right: 14px;
        }
@media (min-width: 943px) {
footer .footer-backlink {
            display: none
    }
        }
@media (max-width: 941px) {
footer .footer-backlink {
            position: relative;
            top: -60px
    }
        }
footer .footer-bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
@media (min-width: 943px) {
footer .footer-bottom {
            padding-left: 82px;
            padding-right: 82px
    }
        }
footer .footer-separator {
        position: absolute;
        left: 0;
        margin-top: -40px;
        width: 100%;
        height: 1px;
        background: var(--color-greyOutline);
    }
footer .footer-info {
        width: 50%;
    }
footer .footer-info h2 {
            font-family: 'Basis Grotesque Ia', sans-serif;
            font-weight: 500;
        }
footer .footer-info p {
            color: var(--color-grey);
            font-size: 14px;
            line-height: 17px;
        }
footer .footer-logos {
        margin-top: 20px;
        display: flex;
    }
footer .footer-logos > a {
            margin-right: 8px;
        }
footer .footer-logos .separator {
            flex: 0 1 auto;
            margin-right: 8px;
        }
footer .footer-newsletter h3 {
            color: var(--color-reaction);
            margin-bottom: 21.5px;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 14px;

            line-height: 18px;
        }
footer .footer-newsletter button {
            margin-left: 14px;
        }
footer .footer-newsletter input {
            background: #e5e5e5;
            border: none;
            border-radius: 32px;
            height: 36px;
            margin-top: 8px;
            padding-left: 15px;
            width: 235px;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 15px;

            line-height: 19px;
        }
@media (max-width: 941px) {
footer .footer-newsletter {
            display: none
    }
        }
@media (min-width: 943px) {
footer {
        background-color: var(--color-white);
        padding: 30px 0 40px;
        padding-top: 80px;
        width: 100%
}

        footer.greyBackground {
            max-width: unset;
            background-color: var(--color-greyBackground);
        }
    }
@media (max-width: 941px) {
footer {       
        background-color: var(--color-greyBackground);
        padding: 80px 40px 40px 40px;
        width: 100%
}
    }
.go-back {
    display: flex;
    width: 100%;
    align-content: center;
}
.go-back a {
        color: var(--color-reaction);
        margin-left: 8px;
        text-decoration: none;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.go-back svg {
        transform: rotate(180deg) translate(-3px, -2px);
        height: 20px;
        width: 20px;
    }
.go-back svg path {
            stroke: var(--color-reaction);
        }
header {
    border-radius: 0 0 20px 20px;
    margin: 0 auto 0px;
    max-width: 1440px;
    padding: 25px 0 25px 0;
    padding-left: 0px;
    width: 100%;
}
header h3 {
        font-family: 'Basis Grotesque Ia', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
    }
header .user-image {
        border: 1px solid var(--color-white);
        flex: 0 1 auto;
        margin-left: 30px;
    }
header .user-image,
        header .user-image > a {
            background-color: var(--color-greyBackground);
            border-radius: 50%;
            display: flex;
            height: 40px;
            overflow: hidden;
            position: relative;
            width: 40px;
        }
header .user-image.click, header .user-image > a.click {
                cursor: pointer;
            }
header .user-image > a {
            border: 4px solid transparent;
        }
header .user-image img {
            margin: auto;
            width: 100%;
        }
@media (max-width: 941px) {
header .user-image {
            visibility: hidden
    }
        }
header .header-top {
        align-content: center;
        align-items: center;
        display: flex;
        flex: 1 1;
        justify-content: space-between;
        margin-top: 20px;
        margin: 0 auto;
    }
header .header-top h3 {
            color: var(--color-reaction);
        }
header .header-top.middle {
            justify-content: center;
        }
header .header-top .hamburger {
            margin-left: 25px;
            margin-right: -50px;
        }
header .header-top .user-content {
            align-items: center;
            display: flex;
        }
header .header-top .user-name {
            color: var(--color-reaction);            
            margin-right: 18px;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
            line-height: 20px;
        }
@media (min-width: 1201px) {
header .header-top {
            display: none
    }
        }
header .header-top-desktop {
        align-items: center;
        display: none;
        justify-content: space-between;
        margin-left: 80px;
        padding-left: 10px;
        padding-right: 80px;
        padding-top: 30px;
        transition: padding 0.25s ease;
    }
header .header-top-desktop > div {
            flex: 0 1 auto;
        }
header .header-top-desktop > div:last-child {
            min-width: 140px;
        }
header .header-top-desktop .float-left {
            flex: 8 1;
            display: flex;
        }
header .header-top-desktop .go-back-wrapper {
            margin-left: -12px;
            width: 306px;
        }
@media (max-width: 1399px) {
header .header-top-desktop .go-back-wrapper {
                width: 270px
        } 
            }
header .header-top-desktop .title-wrapper {
            align-content: center;
            align-items: center;
            border: 3px solid transparent;
            border-radius: 8px;
            display: flex;
            flex: 0 1 auto;
            padding: 5px 15px 5px 15px; 
        }
header .header-top-desktop .title-wrapper a {
                text-decoration: none;
            }
header .header-top-desktop .title-wrapper span {
                color: var(--color-reaction);

                font-family: 'Basis Grotesque Ia', sans-serif;

                font-style: normal;

                font-weight: 500;

                font-size: 16px;

                line-height: 22px}
header .header-top-desktop .title-wrapper svg {
                margin-right: 10px;
            }
header .header-top-desktop .title-wrapper:hover {
                background-color: var(--color-whiteIsh);
                border: 3px solid var(--color-whiteIsh);
            }
header .header-top-desktop .title-wrapper:hover span {
                    text-decoration: underline;
                }
header .header-top-desktop .user-content {
            align-items: center;
            border: 3px solid transparent;
            display: flex;
            justify-content: flex-end;
            margin-right: 30px;
            padding: 5px 5px 5px 5px;
            transition: border-radius .2s ease-out;
        }
header .header-top-desktop .user-content > a:nth-child(1) {
                display: flex;
                flex-direction: column;
                margin-right: 5px;
                text-decoration: none;
            }
header .header-top-desktop .user-content > a:nth-child(1) span:nth-child(1),
                header .header-top-desktop .user-content > a:nth-child(1) span:nth-child(2) {
                    color: var(--color-reaction);

                    font-family: 'Basis Grotesque Ia', sans-serif;

                    font-style: normal;

                    font-weight: 500;

                    font-size: 14px;

                    line-height: 18px;
                }
header .header-top-desktop .user-content:hover {
                border-radius: 8px;
                background-color: var(--color-whiteIsh);
                border: 3px solid var(--color-whiteIsh);
            }
header .header-top-desktop .user-content:hover > a:nth-child(1) span:nth-child(2) {            
                        text-decoration: underline;
                    }
@media (min-width: 1201px) {
header .header-top-desktop {
            display: flex
    }
        }
@media (max-width: 1399px) {
header .header-top-desktop {
            padding-right: 0
    }
        }
@media (min-width: 943px) {
header {
        transition: unset;        
        background-color: var(--color-white)
}

        header.greyBackground  {
            background-color: var(--color-greyBackground)!important;
            border-radius: 0;
            max-width: 1340px;
            margin: 0 auto;
            transition: unset;
            width: 100%;
        }
    }
@media (max-width: 941px) {
header {
        padding-left: 0
}
    }
.login-wrapper {
    background: var(--color-white);
    border-radius: 32px;
}
.login-wrapper h3 {
        align-items: center;
        display: flex;
        line-height: 28px;

        font-family: 'Basis Grotesque Bold Ia', sans-serif;

        font-style: normal;

        font-weight: normal;

        font-size: 24px;

        line-height: 28px;
    }
.login-wrapper h3 img {
            margin-right: 15px;
            width: 20px;
        }
@media (max-width: 941px) {
.login-wrapper {
        width: 100%
} 
    }
.login-fields {
    margin-top: 30px;
}
.login-fields > span {
        line-height: 19px;

        font-family: 'Basis Grotesque Bold Ia', sans-serif;

        font-style: normal;

        font-weight: normal;

        font-size: 15px;

        line-height: 19px;
    }
.login-fields > input {
        background: var(--color-ultraLight);
        border: 1px solid transparent;
        border-radius: 20px;
        display: block;
        margin-bottom: 10px;
        margin-top: 10px;
        max-width: 330px;
        padding: 6px 6px 6px 16px;
        width: 100%;
        outline: none;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.login-fields > input:focus {
            border: 1px solid var(--color-reaction);
        }
.login-error {
    margin-top: 15px;
    color: var(--color-dark);

    font-family: 'Basis Grotesque Ia', sans-serif;

    font-style: normal;

    font-weight: 500;

    font-size: 16px;

    line-height: 22px;
}
nav {
    height: 100%;
    left: -85px;
    margin-bottom: 50px;
    margin-right: 0px;
    min-width: 218px;
    padding-left: 85px;
    position: relative;
    overflow-y: hidden;
    transition: max-height 0.75s ease-out, padding .45s ease-out, margin .45s ease-out;
}
nav.open {
        max-height: 400px;
        margin-bottom: 30px;
        padding-bottom: 20px;
    }
nav ul {
        display: block;
        list-style: none;
        padding: 0;
        margin: 0;
    }
nav li a {
        color: var(--color-reaction);
        text-decoration: none;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 24px;

        line-height: 30px;
    }
nav li a:hover,
        nav li a.active {
            color: var(--color-dark);
        }
nav li a:hover {
            text-decoration: underline;
        }
@media (max-width: 1399px) {
nav {
        margin-left: 10px
}  
    }
@media (min-width: 1201px) {
nav {
        background-color: var(--color-white);   
        position: sticky;
        top: 175px
}
    }
@media (max-width: 1199px) {
nav {
        max-height: 0;
        margin-bottom: 50px;
        margin-left: 85px
}     
    }
@media (max-width: 941px) {
nav {
        width: 100%;
        background-color: var(--color-greyBackground)
}   
    }
.pagination {
    align-items: center;
    align-content: center;
    display: flex;
    height: 20px;
    justify-content: center;
    margin-bottom: 109px;
    margin-top: 125px;
    max-width: 725px;
    width: 100%;
}
.pagination > button {
        flex: 0 1 auto;
        margin: 0;
        padding: 0;
    }
.pagination > button.circle {
        cursor: pointer;
        margin-right: 5px;
        position: relative;
    }
.pagination > button.circle svg {
            height: 46px;
            width: 46px;
            z-index: 2;
        }
.pagination > button.circle svg text {
                text-align: center;

                font-family: 'Basis Grotesque Ia', sans-serif;

                font-style: normal;

                font-weight: 500;

                font-size: 16px;

                line-height: 22px;
            }
.pagination > button.circle:hover svg circle {
                fill: #7c17ff;
            }
.pagination > button.circle:hover svg text {
                fill: white;
            }
.pagination button:first-child svg text {
            transform: translateX(-2px);
            font-family: Akkurat Pro;
        }
.pagination button:last-child svg text {
            transform: translateX(-1px);
            font-family: Akkurat Pro;
        }
.partner-links h1,
    .partner-links h2 {
        color: var(--color-dark);
    }
.partner-links h2:not(.partner-links-contact) {
        margin: 45px auto 0 -320px;
        text-align: center;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 24px;

        line-height: 30px;
    }
@media (max-width: 941px) {
.partner-links h2:not(.partner-links-contact) {
            margin: 50px auto 0 -70px
    }
        }
.partner-links .partner-links-wrapper {
        max-width: 625px;
        margin: 0 auto;
    }
.partner-links .partner-links-wrapper.left {
            margin: 0;
        }
.partner-links .partner-links-wrapper.left ul {
                padding-left: 0;
            }
.partner-links .partner-links-wrapper ul {
            display: block;
            list-style: none;
            margin-top: 10px;
            margin-bottom: 0;
            padding-right: 30px;
            padding-bottom: 50px;
        }
.partner-links .partner-links-wrapper ul li {
                align-content: center;
                align-items: center;
                border-bottom: 1px solid #cccccc;
                border-top: 1px solid transparent;
                display: flex;
                justify-content: space-between;
                margin-top: 25px;
                padding-bottom: 30px;
            }
.partner-links .partner-links-wrapper ul li a {
                    color: var(--color-reaction);
                    text-decoration: none;

                    font-family: 'Basis Grotesque Ia', sans-serif;

                    font-style: normal;

                    font-weight: 500;

                    font-size: 16px;

                    line-height: 22px;
                }
.partner-links .partner-links-wrapper ul li a:hover{
                        text-decoration: underline;
                    }
@media (max-width: 941px) {
.partner-links .partner-links-wrapper ul li a {
                        text-decoration: underline
                }
                    }
.partner-links .partner-links-wrapper ul li .partner-links-image {
                    align-items: center;
                    display: flex;
                    height: 60px;
                    justify-content: center;
                    width: 200px;
                }
.partner-links .partner-links-wrapper ul li .partner-links-image img {
                        max-height: 60px;
                        max-width: 150px;
                    }
@media (max-width: 941px) {
.partner-links .partner-links-wrapper ul li .partner-links-image {
                        justify-content: flex-end
                }

                        .partner-links .partner-links-wrapper ul li .partner-links-image img {
                            max-height: 40px;
                            max-width: 100px;
                        }
                    }
@media (max-width: 941px) {
            .partner-links .partner-links-wrapper .partner-links-image {
                display: none;
            }
            
            .partner-links .partner-links-wrapper a {
                text-decoration: none;
            }

            .partner-links .partner-links-wrapper ul li {
                display: flex;
            }
        }
.step-progress-bar {
    background: var(--color-greyBackground);
    border-radius: 20px;
    display: flex;
    height: 20px;
    margin-bottom: 20px;
    margin-top: 25px;
    width: 100%;
}
.step-progress-bar > span {
        flex: 1 1;
        border: 1px solid var(--color-greyOutline);
        position: relative;
    }
.step-progress-bar > span:first-child {
        border-radius: 20px 0 0 20px;
    }
.step-progress-bar > span:last-child {
        border-right: none;
        border-radius: 0 20px 20px 0;
    }
.step-progress-bar > span.done {
        background-color: var(--color-reaction);
    }
.step-progress-bar > span.circle {
        position: relative;
    }
.step-progress-bar > span.circle svg {
            position: absolute;
            height: 32px;
            width: 32px;
            right: 0;
            z-index: 2;
            transform: translate(50%, -25%);
        }
.step-progress-bar > span.circle svg text {
                text-align: center;

                font-family: 'Basis Grotesque Ia', sans-serif;

                font-style: normal;

                font-weight: 500;

                font-size: 16px;

                line-height: 22px;
                line-height: 20px;
            }
@media (min-width: 768px) {
.step-progress-bar {
        border: 1px solid var(--color-greyOutline)
}
        
        .step-progress-bar > span {
            border: 1px solid transparent;
        }
            .step-progress-bar > span.circle svg {
                height: 32px;
                transform: translate(50%, -25%);
                width: 32px;
            }
                
                .step-progress-bar > span.circle svg text {
                    font-size: 12px;
                    line-height: 20px;
                }

        .step-progress-bar.large {
            height: 34px;
        }
                .step-progress-bar.large > span.circle svg {
                    height: 46px;
                    transform: translate(50%, -15%);
                    width: 46px;
                }
                    
                    .step-progress-bar.large > span.circle svg text {
                        font-size: 14px;
                        line-height: 0px;
                        transform: translate(-1px, -.5px);
                    }
    }
.user-card {
    display: flex;
    padding: 0 25px 25px 25px;
    width: 100%;
}
.user-card-info {
    display: flex;
    flex: 3 1;
    flex-direction: column;
}
.user-card-info h3 {
        color: var(--color-reaction);
        line-height: 28px;

        font-family: 'Basis Grotesque Bold Ia', sans-serif;

        font-style: normal;

        font-weight: normal;

        font-size: 24px;

        line-height: 28px;
    }
.user-card-info span:nth-child(2),
    .user-card-info span:nth-child(3) {
        font-family: 'Basis Grotesque Ia', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;

    }
.user-card-info span:nth-child(2) {
        color: var(--color-dark);
        margin-top: 20px;
    }
.user-card-info a:nth-child(3) {
        color: var(--color-reaction);
        text-decoration: underline;
    }
.user-card-image {
    border: 1px solid var(--color-white);
    flex: 0 1 auto;
}
.user-card-image,
    .user-card-image > div {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        position: relative;
        background-color: var(--color-greyBackground);
    }
.user-card-image.click, .user-card-image > div.click {
            cursor: pointer;
        }
.user-card-image.click:not(:hover) > svg, .user-card-image > div.click:not(:hover) > svg {
                opacity: 0;
            }
.user-card-image > div {
        border: 5px solid transparent;
    }
.user-card-image img {
        width: 100%;
        margin: auto;
    }
.user-card-image svg {
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        height: 50px;
        width: 50px;
    }
.page-ambassador-wrapper {
    background: var(--color-white);
    border-radius: 30px;
    position: relative;
}
.page-ambassador-wrapper h1 {
        color: var(--color-dark);
        margin-bottom: 30px;
        word-break: break-all;
    }
.page-ambassador-wrapper .text-wrapper {
        margin-top: 40px;
    }
.page-ambassador-wrapper .text-wrapper h1,
        .page-ambassador-wrapper .text-wrapper h2,
        .page-ambassador-wrapper .text-wrapper h3,
        .page-ambassador-wrapper .text-wrapper h4,
        .page-ambassador-wrapper .text-wrapper h5,
        .page-ambassador-wrapper .text-wrapper h6 {
            color: var(--color-reaction);
            font-weight: 500;
            line-height: 1.4;
        }
.page-ambassador-wrapper .text-wrapper h1 {
            font-size: 28px;
        }
.page-ambassador-wrapper .text-wrapper h2 {
            font-size: 24px;
        }
.page-ambassador-wrapper .text-wrapper h3 {
            font-size: 22px;
        }
.page-ambassador-wrapper .text-wrapper h4 {
            font-size: 20px;
        }
.page-ambassador-wrapper .text-wrapper h5 {
            font-size: 18px;
        }
.page-ambassador-wrapper .text-wrapper h6 {
            font-size: 16px;
        }
@media (max-width: 941px) {
.page-ambassador-wrapper {
        padding: 20px
}
    }
.ambassador-card {
    border-radius: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.ambassador-card .image-wrapper {
        flex: 1 1;
        width: 100%;
    }
.ambassador-card .image-wrapper img {
            width: 100%;
        }
@media (min-width: 768px) {
.ambassador-card .image-wrapper {
            height: 405px;
            overflow: hidden
    }
            
            .ambassador-card .image-wrapper img {
                width: auto;
                height: 100%;
            }
        }
@media (max-width: 766px) {
.ambassador-card .image-wrapper {
            border-radius: 20px 20px 0 0;
            flex: 0 0 auto;
            height: 150px;
            overflow-Y: hidden;
            width: 100%
    }

            .ambassador-card .image-wrapper img {
                height: initial;
                position: relative;
                top: -25%;
                width: 100%;
            }
        }
.ambassador-card .text-content {
        flex: 1 1;
        padding-top: 25px;
        position: relative;
        text-decoration: none;
    }
.ambassador-card .text-content .arrow {
            display: none;
        }
.ambassador-card .text-content > span {
            align-items: flex-end;
            align-content: flex-end;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            padding-right: 2px;
            width: 100%;
        }
.ambassador-card .text-content > span img {
                flex: 2 1;
                margin-right: 5px;
                max-height: 30px;
                max-width: 30px;
            }
.ambassador-card .text-content h2 {
            color: var(--color-white);
            line-height: 20px;
            margin-bottom: 8px;
            text-decoration-line: underline;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.ambassador-card .text-content p {
            color: var(--color-white);
            flex: 12 1;
            line-height: 20px;
            margin-bottom: 0;
            margin-top: 5px;
            max-width: 375px;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
@media (min-width: 768px) {
.ambassador-card .text-content {
            padding-top: 68px
    }

            .ambassador-card .text-content .arrow {
                display: initial;
            }

            .ambassador-card .text-content .whiteArrow {
                display: none;
            }

            .ambassador-card .text-content h2 {
                color: var(--color-reaction);
                text-decoration-line: none;
                
                font-family: 'Basis Grotesque Ia', sans-serif;
                
                font-style: normal;
                
                font-weight: 500;
                
                font-size: 24px;
                
                line-height: 30px;
            }

            .ambassador-card .text-content p {
                color: var(--color-dark);
            }

            .ambassador-card .text-content > span {
                align-items: center;
                display: flex;
                justify-content: space-between;
                padding-right: 50px;
                padding-left: 55px;
                width: 100%;
            }
        }
@media (max-width: 766px) {
.ambassador-card .text-content {
            background-color: var(--color-reaction);
            border-radius: 0 0 20px 20px;
            padding: 20px 0  40px 30px
    }

            .ambassador-card .text-content > span {  
                padding-bottom: 6px;
                padding-right: 6px;
                margin-left: 0;
                align-items: center;
            }
                
                .ambassador-card .text-content > span img {
                    max-height: 20px;
                    max-width: 20px;
                }        
        }
@media (min-width: 768px) {
.ambassador-card {       
        background-color: var(--color-ultraLight);
        flex-direction: row
}

        .ambassador-card > div {
            flex: 1 1;
        }

        .ambassador-card:hover {
            background-color: var(--color-reaction);
        }

            .ambassador-card:hover div,
            .ambassador-card:hover a,
            .ambassador-card:hover p,
            .ambassador-card:hover h2 {
                color: var(--color-white);
            }

            .ambassador-card:hover h2 {
                text-decoration: underline;
            }

            .ambassador-card:hover svg path {
                fill: var(--color-white);
            }
    }
@media (max-width: 766px) {
.ambassador-card { 
        border-radius: 20px;
        padding: 0 20px;
        width: 100%
}
    }
.page-course-wrapper {
    background: var(--color-white);
    border-radius: 30px;
    position: relative;
    
}
.page-course-wrapper h1 {
        color: var(--color-dark);
        margin-bottom: 30px;
    }
.page-course-wrapper > .btn {
        margin-left: 8px;
        margin-top: 25px;
    }
@media (min-width: 768px) {
.page-course-wrapper > .btn {
            background: initial;
            color: var(--color-reaction);
            position: absolute;
            right: 15px;   
            top: 0
    }

            .page-course-wrapper > .btn:hover {
                background: var(--color-reaction);
                color: var(--color-white);
            }
        }
.page-course-wrapper .divider-wrapper {
        margin-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
    }
.page-course-wrapper .divider-wrapper svg {
            width: 100%;
            height: auto;
        }
@media (min-width: 768px) {
.page-course-wrapper .divider-wrapper {
            display: none
    }
        }
@media (max-width: 941px) {
.page-course-wrapper {
        padding: 20px
    
}
    }
.page-databank-wrapper {
    background: var(--color-white);
    border-radius: 30px;
    position: relative;
    margin: 0 auto;
    max-width: 900px;
}
.page-databank-wrapper h1 {
        color: var(--color-dark);
        margin-bottom: 20px;
        padding: 0;
    }
.page-databank-wrapper h2 {
        color: var(--color-dark);
        margin-bottom: 15px;
    }
.page-databank-wrapper .intro {
        max-width: 525px;
        margin-bottom: 100px;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.page-databank-wrapper .small-intro {
        max-width: 400px;
        margin-bottom: 40px;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.page-databank-wrapper .procedures-wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
        width: 100%;
    }
.page-databank-wrapper .procedures-wrapper .dropdown-wrapper {
            display: flex;
            flex-direction: row;
            height: 2em;
            justify-content: flex-start;
            position: relative;
        }
@media (max-width: 941px) {
.page-databank-wrapper .procedures-wrapper .dropdown-wrapper {
                flex-direction: column;
                margin-bottom: 25px
        }   
            }
.page-databank-wrapper .procedures-wrapper .dropdown-list-wrapper {
            margin-right: 15px;
            min-width: 200px;
            max-width: 390px;
            position: relative;
            z-index: 1;
        }
.page-databank-wrapper .procedures-wrapper .dropdown-list-wrapper > div:not(.dropdown-list) {
                background-color: var(--color-white);
                border: 1px solid var(--color-reaction);
                border-radius: 32px;
                color: var(--color-reaction);
                cursor: pointer;
                padding: 8px 10px 8px 20px;
                
                font-family: 'Basis Grotesque Ia', sans-serif;
                
                font-style: normal;
                
                font-weight: 500;
                
                font-size: 16px;
                
                line-height: 22px;
            }
.page-databank-wrapper .procedures-wrapper .dropdown-list-wrapper > div:not(.dropdown-list) svg {
                    height: 20px;
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    transform: rotate(90deg);
                    width: auto;
                }
.page-databank-wrapper .procedures-wrapper .dropdown-list-wrapper > div:not(.dropdown-list) svg path {
                        stroke: var(--color-reaction);
                    }
.page-databank-wrapper .procedures-wrapper .dropdown-list {
            background-color: var(--color-white);
            border: 0px solid var(--color-greyOutline);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            left: 2px;
            list-style-type: none;
            margin: 0;
            margin-top: 10px;
            max-height: 0px;
            overflow-y: hidden;
            padding: 0;
            position: relative;
            transition: max-height 0.15s ease-in;
            z-index: -1;
        }
.page-databank-wrapper .procedures-wrapper .dropdown-list li {
                border-bottom: 1px solid var(--color-greyOutline);
                color: var(--color-reaction);
                cursor: pointer;
                font-weight: 400;
                line-height: 19px;
                padding: 14px 0px 6px 0px;
                margin: 0 15px 0 15px;

                font-family: 'Basis Grotesque Ia', sans-serif;

                font-style: normal;

                font-weight: 500;

                font-size: 16px;

                line-height: 22px;
            }
.page-databank-wrapper .procedures-wrapper .dropdown-list li:hover {
                    text-decoration: underline;
                }
.page-databank-wrapper .procedures-wrapper .dropdown-list li:first-child {
                padding-top: 20px;
            }
.page-databank-wrapper .procedures-wrapper .dropdown-list li:nth-child(n + 2) {
            }
.page-databank-wrapper .procedures-wrapper .dropdown-list li:last-child {
                padding-bottom: 20px;
            }
.page-databank-wrapper .procedures-wrapper .dropdown-list.open {
                border: 1px solid var(--color-greyOutline);
                max-height: 800px;
            }
.page-databank-wrapper .procedures-wrapper .selected-wrapper {
            width: 100%;
            margin-top: 50px;
            margin-bottom: 18px;
        }
.page-databank-wrapper .procedures-wrapper .selected-wrapper h3 {
                display: inline-flex;
                flex-wrap: wrap;
            }
.page-databank-wrapper .procedures-wrapper .selected-wrapper h3 > * {
                    margin-left: 5px;
                }
.page-databank-wrapper .procedures-wrapper .selected-wrapper h3 div {
                    font-family: 'Basis Grotesque Bold Ia', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                }
.page-databank-wrapper .procedures-wrapper .selected-wrapper h3 {

                font-family: 'Basis Grotesque Ia', sans-serif;

                font-style: normal;

                font-weight: 500;

                font-size: 16px;

                line-height: 22px;
}
.page-databank-wrapper .procedures-wrapper .procurement-wrapper {
            border-top: 2px solid var(--color-ultraLight);
            padding: 23px 23px 23px 0;
            position: relative;
        }
.page-databank-wrapper .procedures-wrapper .procurement-wrapper a {
                text-decoration: none;
            }
.page-databank-wrapper .procedures-wrapper .procurement-wrapper h3 {
                color: var(--color-reaction);
                margin-bottom: 0;
                
                font-family: 'Basis Grotesque Bold Ia', sans-serif;
                
                font-style: normal;
                
                font-weight: normal;
                
                font-size: 16px;
                
                line-height: 22px;
            }
.page-databank-wrapper .procedures-wrapper .procurement-wrapper h3:hover{
                    text-decoration: underline;
                }
.page-databank-wrapper .procedures-wrapper .procurement-date {
            text-transform: capitalize;
        }
@media (min-width: 768px) {
.page-databank-wrapper .procedures-wrapper .procurement-date {
                margin-bottom: 30px
        }
            }
.page-databank-wrapper .procedures-wrapper .procurement-ongoing {
            border: 1px solid var(--color-reaction);
            border-radius: 4px;
            color: var(--color-reaction);
            padding: 2px 8px ;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 12px;

            line-height: 20px;;
        }
@media (min-width: 768px) {
.page-databank-wrapper .procedures-wrapper .procurement-ongoing {
                position: absolute;
                top: 20px;
                right: 0;
        }
            }
@media (max-width: 766px) {
.page-databank-wrapper .procedures-wrapper .procurement-ongoing {
                display: inline-block;
                margin-left: -2px;
                margin-top: 5px;
                margin-bottom: 10px;
        }
            }
.page-databank-wrapper .procedures-wrapper .procurement-done {
            background: var(--color-ultraLight);;
            border-radius: 4px;
            color: var(--color-dark);;
            padding: 8px;
            
            font-family: 'Basis Grotesque Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: 500;
            
            font-size: 12px;
            
            line-height: 20px;
        }
@media (min-width: 768px) {
.page-databank-wrapper .procedures-wrapper .procurement-done {
                position: absolute;
                right: 0;
                top: 15px
        }
            }
@media (max-width: 766px) {
.page-databank-wrapper .procedures-wrapper .procurement-done {
                display: inline-block;
                margin-left: -2px;
                margin-top: 5px;
                margin-bottom: 10px
        }
            }
.page-databank-wrapper .procedures-wrapper .procurement-procedure {
            color: var(--color-grey);
            margin-bottom: 20px;
        }
.page-databank-wrapper .procedures-wrapper .procurement-procedure span {
                margin-right: 5px;
            }
.page-databank-wrapper .procedures-wrapper .procurement-actor {
            color: var(--color-grey);
        }
.page-databank-wrapper .procedures-wrapper .procurement-actor span {
                margin-right: 5px;
                opacity: 0.75;
            }
@media (max-width: 941px) {
.page-databank-wrapper { 
        padding-bottom: 50px;
        padding: 40px 20px
}

    }
.page-dialog-wrapper {
    background: var(--color-white);
    border-radius: 30px;
    position: relative;
}
.page-dialog-wrapper h1 {
        color: var(--color-dark);
    }
@media (min-width: 768px) {
.page-dialog-wrapper .search-wrapper {
            background-color: var(--color-ultraLight);
            border-radius: 20px;
            padding: 55px 90px 55px 102px;
            max-width: 735px;
            margin-top: 40px;
            margin-bottom: 66px
    }
        }
.page-dialog-wrapper .scroller-wrapper h2 {
            color: var(--color-dark);
        }
@media (min-width: 768px) {
.page-dialog-wrapper .scroller-wrapper {
                margin-bottom: 60px
    }
        }
.page-dialog-wrapper .dialog-intro .title-wrapper {
            display: flex;
            justify-content: space-between;
            max-width: 735px;
        }
.page-dialog-wrapper .dialog-intro .title-wrapper a {
                text-decoration: none;
                margin: 0;
            }
.page-dialog-wrapper .dialog-intro .title-wrapper h1 {
                color: var(--color-dark);
            }
.page-dialog-wrapper .dialog-intro .title-wrapper h2:nth-child(2) {
                display: none;
            }
@media (min-width: 943px) {
                .page-dialog-wrapper .dialog-intro .title-wrapper h2:nth-child(1){
                    display: none;
                }

                .page-dialog-wrapper .dialog-intro .title-wrapper h2:nth-child(2){
                    display: initial;
                }
            }
.page-dialog-wrapper .dialog-intro p {
            font-family: 'Basis Grotesque Ia', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }
.page-dialog-wrapper .search-bar {
        margin-bottom: 10px;
        margin-top: 15px;
    }
.page-dialog-wrapper .search-bar h3 {
            margin-bottom: 10px;
        }
@media (min-width: 943px) {
.page-dialog-wrapper .search-bar h3 {
                margin-bottom: 20px
        }
            }
.page-dialog-wrapper .search-bar input {
            background-color: var(--color-greyBackground);
            border: none;
            border-radius: 20px;
            color: var(--color-grey);    
            display: block;
            outline: none;
            padding: 8px 8px 8px 16px;
            width: 100%;
            
            font-family: 'Basis Grotesque Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: 500;
            
            font-size: 16px;
            
            line-height: 22px;
        }
.page-dialog-wrapper .search-bar input ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: var(--color-grey);    
            }
@media (min-width: 943px) {
.page-dialog-wrapper .search-bar input {
                background-color: var(--color-white)
        }
            }
.page-dialog-wrapper .category-filter h3 {
            color: var(--color-dark);
            margin-top: 15px;

            font-family: 'Basis Grotesque Bold Ia', sans-serif;

            font-style: normal;

            font-weight: normal;

            font-size: 16px;

            line-height: 22px;
        }
@media (min-width: 943px) {
.page-dialog-wrapper .category-filter h3 {
                margin-top: 55px
        }
            }
.page-dialog-wrapper .category-filter .filter-list {
            max-width: 625px;
        }
.page-dialog-wrapper .category-filter .filter-list ul {
                list-style: none;
                padding: 0;
                margin-top: 10px;
                padding-bottom: 50px;
                margin-bottom: 0;
                width: 100%;
            }
.page-dialog-wrapper .category-filter .filter-list ul li {
                    cursor: pointer;
                    padding-bottom: 10px;
                    margin-top: 25px;
                    border-bottom: 1px solid var(--color-greyOutline);
                    color: var(--color-reaction);

                    font-family: 'Basis Grotesque Ia', sans-serif;

                    font-style: normal;

                    font-weight: 500;

                    font-size: 16px;

                    line-height: 22px;
                }
.page-dialog-wrapper .category-filter .filter-list ul li div:nth-child(3) {
                        display: none;
                    }
.page-dialog-wrapper .category-filter .filter-list ul li:hover span {
                        color: var(--color-dark);
                    }
@media (min-width: 943px) {
                .page-dialog-wrapper .category-filter .filter-list ul {
                    margin-top: 10px;
                }

                    .page-dialog-wrapper .category-filter .filter-list ul li {
                        margin-top: 15px;
                    }

                        .page-dialog-wrapper .category-filter .filter-list ul li div {
                            align-content: center;
                            align-items:center;    
                            display: flex;
                            justify-content: space-between;
                        }
                        
                        .page-dialog-wrapper .category-filter .filter-list ul li span:nth-child(2) {
                            display: none;
                        }
                        .page-dialog-wrapper .category-filter .filter-list ul li div:nth-child(3){
                            background-color: var(--color-ultraLight);
                            border: unset;
                            display: initial;
                        }
            
            }
@media (max-width: 941px) {
                .page-dialog-wrapper .category-filter .filter-list ul {
                    padding-right: 0;
                }

                    .page-dialog-wrapper .category-filter .filter-list ul li {
                        margin-top: 15px;
                        padding-bottom: 10px;

                        font-family: 'Basis Grotesque Ia', sans-serif;

                        font-style: normal;

                        font-weight: 500;

                        font-size: 16px;

                        line-height: 22px;
                    }
            }
.page-dialog-wrapper .category-filter .number-badge {
            border: 1px solid var(--color-reaction);
            border-radius: 32px;
            color: var(--color-reaction);
            cursor: pointer;
            display: inline-block;            
            padding: 6px 15px 8px;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
            line-height: 20px;
        }
.page-dialog-wrapper .dialog-lister .title-wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            max-width: 735px;
        }
.page-dialog-wrapper .dialog-lister .title-wrapper a {
                text-decoration: none;
            }
.page-dialog-wrapper .dialog-lister .title-wrapper h2{
                color: var(--color-dark);
                
                font-family: 'Basis Grotesque Bold Ia', sans-serif;
                
                font-style: normal;
                
                font-weight: normal;
                
                font-size: 27px;
                
                line-height: 34px;
            }
.page-dialog-wrapper .dialog-lister .title-wrapper h2 span {
                    text-transform: capitalize;
                }
.page-dialog-wrapper .dialog-lister .title-wrapper h2 img {
                    cursor: pointer;
                    display: inline-block;
                }
.page-dialog-wrapper .dialog-lister .title-wrapper .btn {
                margin: 0;
            }
.page-dialog-wrapper .dialog-lister .list {
            max-width: 750px;
        }
.page-dialog-wrapper .dialog-scroller .tags {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            margin-top: 30px;
            width: calc(100% + 35px);
        }
@media (max-width: 941px) {
.page-dialog-wrapper .dialog-scroller .tags {
                width: calc(100% + 15px);
                flex-wrap: nowrap;
                overflow: scroll
        }
            }
.page-dialog-wrapper .dialog-scroller .tag-wrapper {
            align-content: flex-start;
            align-items: flex-start;
            background-color: var(--color-reaction);
            border: 1px solid var(--color-reaction);
            border-radius: 20px;
            color: var(--color-white);
            display: flex;
            flex: 0 1 auto;
            justify-content: space-between;
            flex-direction: column;
            max-width: 300px;
            margin-bottom: 10px;
            padding: 15px;
            text-decoration: none;
            width: calc((100% - 20px) / 3);
        }
.page-dialog-wrapper .dialog-scroller .tag-wrapper > div:first-child {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;

                font-family: 'Basis Grotesque Bold Ia', sans-serif;

                font-style: normal;

                font-weight: normal;

                font-size: 16px;

                line-height: 22px;
            }
.page-dialog-wrapper .dialog-scroller .tag-wrapper > div:first-child span {
                    font-family: 'Basis Grotesque Ia', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                }
.page-dialog-wrapper .dialog-scroller .tag-wrapper * {
                transition: color 0s ease;
            }
.page-dialog-wrapper .dialog-scroller .tag-wrapper:hover,
            .page-dialog-wrapper .dialog-scroller .tag-wrapper:not(.primary) {
                background-color: var(--color-white);
                border: 1px solid var(--color-greyOutline);
                color: var(--color-dark);
            }
.page-dialog-wrapper .dialog-scroller .tag-wrapper:nth-child(3n + 2),
            .page-dialog-wrapper .dialog-scroller .tag-wrapper:nth-child(3n + 1) {
                margin-right: 10px;
            }
.page-dialog-wrapper .dialog-scroller .tag-wrapper:nth-child(n + 4) {
                animation: moveitmoveit 0.35s forwards;
                animation-timing-function: ease-out;
            }
@keyframes moveitmoveit {
                0% {
                    transform: translateY(-55%);
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    transform: translateY(0%);
                }
            }
@media (max-width: 941px) {
.page-dialog-wrapper .dialog-scroller .tag-wrapper {
                width: 300px;
                min-width: 300px
        }
                .page-dialog-wrapper .dialog-scroller .tag-wrapper:nth-child(3n + 2),
                .page-dialog-wrapper .dialog-scroller .tag-wrapper:nth-child(3n + 1) {
                    margin-right: 0px;
                }
            }
.page-dialog-wrapper .dialog-scroller .tag {
            background-color: var(--color-white);
        }
.page-dialog-wrapper .dialog-scroller .tag:hover {
                background: initial;
                color: var(--color-reaction);
            }
@media (max-width: 941px) {
.page-dialog-wrapper {
        padding: 20px
}
    }
@media (max-width: 766px) {
.page-dialog-wrapper {
        padding-right: 40px
}
    }
/*
/ Globally used components
*/
.dialog-entry-card {
    align-items: flex-start;
    background-color: var(--color-ultraLight);
    border-radius: 20px;
    color: var(--color-dark);
    display: flex;
    margin: 30px 0;
    padding: 35px 40px 15px 25px;
    position: relative;
    text-decoration: none;
}
.dialog-entry-card.line {
        border-bottom: 1px solid var(--color-greyOutline);
    }
.dialog-entry-card.isOpen {
        background-color: transparent;
    }
.dialog-entry-card .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 18px;
        width: 100%;
    }
.dialog-entry-card .star-wrapper {
        bottom: 20px;
        position: absolute;
        right: 20px;
    }
@media (min-width: 943px) {
.dialog-entry-card .star-wrapper {
            bottom: unset;
            top: 30px;
            right: 40px
    }
        }
.dialog-entry-card .top a {
            text-decoration: none;
        }
.dialog-entry-card .top h3 {
            color: var(--color-reaction);
        }
.dialog-entry-card .top h3:hover {
                text-decoration: underline;
            }
.dialog-entry-card .top span {
            font-family: 'Basis Grotesque Ia', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            font-weight: 400;
        }
.dialog-entry-card .image {
        flex: 0 0 auto;
        border: 1px solid var(--color-white);
    }
.dialog-entry-card .image,
        .dialog-entry-card .image > div {
            background-color: var(--color-greyBackground);
            border-radius: 50%;
            display: flex;
            height: 75px;
            overflow: hidden;
            position: relative;
            width: 75px;
        }
.dialog-entry-card .image.click, .dialog-entry-card .image > div.click {
                cursor: pointer;
            }
.dialog-entry-card .image.click:not(:hover) > svg, .dialog-entry-card .image > div.click:not(:hover) > svg {
                    opacity: 0;
                }
.dialog-entry-card .image > div {
            border: 5px solid transparent;
        }
.dialog-entry-card .image img {
            width: 100%;
            margin: auto;
        }
.dialog-entry-card .image svg {
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
            height: 50px;
            width: 50px;
        }
@media (max-width: 941px) {
            .dialog-entry-card .image,
            .dialog-entry-card .image > div {
                    height: 55px;
                    width: 55px;
            }

            .dialog-entry-card .image img {
                position: absolute;
                top: calc(50% - 28px);
            } 
        }
.dialog-entry-card .tag {
        background-color: var(--color-ultraLight);
        border: 0;
        margin-bottom: 3px;
    }
.dialog-entry-card .tag:hover {
            background-color: var(--color-reaction);
        }
.dialog-entry-card .text {
        border-left: 2px solid var(--color-white);
        margin: 28px 0 48px 0;
        max-width: 520px;
        padding: 0 18px;
        white-space: pre-line;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
        font-weight: 400;
    }
.dialog-entry-card .text.isOpen {
            border: unset;
            padding: 0;
        }
@media (max-width: 941px) {
            .dialog-entry-card .text:not(.isOpen){
                display: none;
            }
        }
@media (max-width: 941px) {
.dialog-entry-card .tag-wrapper {
            margin-top: 25px
    }
        }
@media (max-width: 941px) {
.dialog-entry-card .replies-wrapper {
            display: none
    }
        }
.dialog-entry-card .bottom {
        align-items: center;
        align-content: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
.dialog-entry-card .name {
        color: var(--color-reaction);
        display: inline-block;

        font-family: 'Basis Grotesque Bold Ia', sans-serif;

        font-style: normal;

        font-weight: normal;

        font-size: 16px;

        line-height: 22px;
    }
.dialog-entry-card .time {
        position: absolute;
        right: 35px;
        top: 32px;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
        font-weight: 400;
    }
@media (max-width: 941px) {
.dialog-entry-card .time {
            right: 20px;
            top: 0
    }

            .dialog-entry-card .time > span {
                display: none;
            }
        }
@media (min-width: 943px) {
.dialog-entry-card {
        padding: 35px 40px 40px 25px
}
    }
.dialog-entry-card-mobile h1 {
        margin-top: 15px;
        text-transform: Capitalize;

        font-family: 'Basis Grotesque Bold Ia', sans-serif;

        font-style: normal;

        font-weight: normal;

        font-size: 24px;

        line-height: 28px;
    }
.dialog-entry-card-mobile .mobile-name span, .dialog-entry-card-mobile-reply .mobile-name span {
            font-family: 'Basis Grotesque Bold Ia', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
.dialog-entry-card-mobile .mobile-text, .dialog-entry-card-mobile-reply .mobile-text {
        margin: 28px 0 48px 0;

        font-family: 'Basis Grotesque Bold Ia', sans-serif;

        font-style: normal;

        font-weight: normal;

        font-size: 16px;

        line-height: 22px;
    }
@media (max-width: 941px) {
            .dialog-entry-card-mobile .mobile-text:not(.isOpen),.dialog-entry-card-mobile-reply .mobile-text:not(.isOpen){
                display: none;
            }
        }
.page-edit-user-wrapper {
    background: var(--color-white);
    border-radius: 30px;
    max-width: 900px;
    padding: 32px 20px 85px;
}
.page-edit-user-wrapper .courses-header {
        margin-top: 80px
    }
.page-edit-user-wrapper .top-wrapper {
        display: flex;
    }
.page-edit-user-wrapper .top-wrapper .left > div h1 {
                    color: var(--color-dark);
                }
.page-edit-user-wrapper .top-wrapper .left > div:nth-child(2) {
                    font-family: 'Basis Grotesque Bold Ia', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                }
.page-edit-user-wrapper .top-wrapper .left > div:nth-child(n + 3) {
                    font-family: 'Basis Grotesque Ia', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                }
.page-edit-user-wrapper .top-wrapper .right {

        }
.page-edit-user-wrapper .top-wrapper .image-wrapper {
            border: 1px solid var(--color-white);
            flex: 0 1 auto;
        }
.page-edit-user-wrapper .top-wrapper .image-wrapper,
            .page-edit-user-wrapper .top-wrapper .image-wrapper > div {
                background-color: var(--color-greyBackground);
                border-radius: 50%;
                display: flex;
                height: 100px;
                overflow: hidden;
                position: relative;
                width: 100px;
            }
.page-edit-user-wrapper .top-wrapper .image-wrapper.click, .page-edit-user-wrapper .top-wrapper .image-wrapper > div.click {
                    cursor: pointer;
                }
.page-edit-user-wrapper .top-wrapper .image-wrapper.click:not(:hover) > svg, .page-edit-user-wrapper .top-wrapper .image-wrapper > div.click:not(:hover) > svg {
                        opacity: 0;
                    }
.page-edit-user-wrapper .top-wrapper .image-wrapper > div {
                border: 5px solid transparent;
            }
.page-edit-user-wrapper .top-wrapper .image-wrapper img {
                margin: auto;
                width: 100%;
            }
.page-edit-user-wrapper .top-wrapper .image-wrapper svg {
                height: 50px;
                left: calc(50% - 25px);
                position: absolute;
                top: calc(50% - 25px);
                width: 50px;
            }
.page-edit-user-wrapper .edit-wrapper {
        max-height: 0;
        overflow: hidden;
        transform: translate(+150%, 150%, 200%);
        transition: max-height 0.35s ease-in-out, transform 0.5s ease-in-out;
    }
.page-edit-user-wrapper .edit-wrapper.open {
            max-height: 600px;
            transform: translateY(0);
        }
.page-edit-user-wrapper .fields-wrapper {
        margin-top: 30px;
    }
.page-edit-user-wrapper .fields-wrapper > span {
            font-family: 'Basis Grotesque Bold Ia', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 19px;
        }
.page-edit-user-wrapper .fields-wrapper > input {
            padding-left: 16px;
        }
.page-edit-user-wrapper .fields-wrapper .password {
            width: 100%;
        }
.page-edit-user-wrapper .fields-wrapper .password > div {
                width: 100%;
                display: flex;
                align-items: flex-end;
            }
.page-edit-user-wrapper .fields-wrapper .password > div > div:nth-child(2) {
                    margin-bottom: 7px;
                    margin-left: 15px;
                }
.page-edit-user-wrapper .fields-wrapper .password input {
                width: 250px;
                padding-left: 16px;
            }
.page-edit-user-wrapper .fields-wrapper .password:nth-child(2) input {
                width: 100%;
            }
.page-edit-user-wrapper .fields-wrapper .password span {
                font-family: 'Basis Grotesque Bold Ia', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 19px;
            }
.page-edit-user-wrapper .fields-wrapper .password .btn {
                margin: 0 0 10px 15px;
            }
.page-edit-user-wrapper input {
        background: var(--color-ultraLight);
        border: 0;
        border-radius: 20px;
        color: var(--color-reaction);
        display: block;
        margin: 10px 0;
        max-width: 730px;
        outline: none;
        padding: 6px;
        width: 100%;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.page-edit-user-wrapper input::placeholder{
            color: var(--color-reaction);

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.page-edit-user-wrapper input.error{
            border: 1px solid red;
        }
.page-edit-user-wrapper .image-input {
        display: none;
    }
@media (--breakpoint-table-down) {
.page-edit-user-wrapper {
        margin: 0 auto;
        max-width: 450px
}    
    }
.page-examples-wrapper {
    background: var(--color-white);
    border-radius: 30px;
    position: relative;
}
.page-examples-wrapper .inner-wrapper {
        background-color: var(--color-ultraLight);
        border-radius: 20px;
        margin: 0 0 20px 0;
        padding: 80px 100px;
    }
.page-examples-wrapper .inner-wrapper h2 {
            color: var(--color-dark);
            margin-bottom: 15px;
        }
.page-examples-wrapper .inner-wrapper .small-intro {
            max-width: 400px;
            margin-bottom: 40px;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px}
.page-examples-wrapper .inner-wrapper .file-wrapper {
            background: linear-gradient(
                to right,
                var(--color-white) 50%,
                var(--color-ultraLight) 50%
            );
            background-size: 200% 100%;
            border: 1px solid var(--color-white);
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            overflow: hidden;
            width: 100%;
        }
.page-examples-wrapper .inner-wrapper .file-wrapper span {
                margin-left: 5px;
            }
@media (max-width: 941px) {
.page-examples-wrapper .inner-wrapper .file-wrapper {
                background: var(--color-greyBackground)
        }
    
                .page-examples-wrapper .inner-wrapper .file-wrapper div {
                    background-color: var(--color-greyBackground);
                }
            }
.page-examples-wrapper .inner-wrapper .file-name {
            display: flex;
            flex: 6 1;
            padding: 12px 20px;
        }
.page-examples-wrapper .inner-wrapper .file-name img {
                transform: translate(-4px, 3px);
            }
.page-examples-wrapper .inner-wrapper .file-name,
            .page-examples-wrapper .inner-wrapper .file-name a {
                color: var(--color-reaction);
                cursor: pointer;
                text-decoration: none;
    
                font-family: 'Basis Grotesque Ia', sans-serif;
    
                font-style: normal;
    
                font-weight: 500;
    
                font-size: 16px;
    
                line-height: 22px}
.page-examples-wrapper .inner-wrapper .file-name.bold,
            .page-examples-wrapper .inner-wrapper .file-name .bold {
                font-family: 'Basis Grotesque Bold Ia', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px}
.page-examples-wrapper .inner-wrapper .arrow-wrapper {
            background-color: var(--color-white);
            height: 100%;
            padding: 15px 23px;
        }
.page-examples-wrapper .inner-wrapper .arrow-wrapper.rotate {
                transform: rotate(-90deg);
            }
.page-examples-wrapper .inner-wrapper .small-file-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
.page-examples-wrapper .inner-wrapper .small-file-wrapper > div {
                width: calc(50% - 8px);
            }
@media (max-width: 941px) {
                .page-examples-wrapper .inner-wrapper .small-file-wrapper > div {
                    width: 100%;
                }        
            }
@media (max-width: 941px) {
.page-examples-wrapper .inner-wrapper {
            background-color: var(--color-white);
            padding: 0
    }
        }
@media (max-width: 941px) {
.page-examples-wrapper {
        padding: 40px 20px
}
    }
.page-forum-entry-wrapper {
    border-radius: 30px;
    background: var(--color-white);
}
.page-forum-entry-wrapper h1 {
        color: var(--color-dark);
        margin-bottom: 26px;
    }
.page-forum-entry-wrapper h2 {
        color: var(--color-dark);
        margin-top: 40px;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 15px;

        line-height: 19px;
    }
.page-forum-entry-wrapper .banner {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
@media (max-width: 766px) {
            .page-forum-entry-wrapper .banner > div{
                display: none;
            }
        }
@media (max-width: 766px) {
.page-forum-entry-wrapper .open-banner {
            display: none
    }
        }
.page-forum-entry-wrapper .go-back {
        padding-left: 30px;
        margin-bottom: 40px;
    }
@media (min-width: 768px) {
.page-forum-entry-wrapper .go-back {
            display: none
    }
        }
.page-forum-entry-wrapper .edit-wrapper {
        margin-top: 50px;
        padding-right: 35px;
    }
.page-forum-entry-wrapper .edit-wrapper .edit-fields {
            margin-top: 60px;
        }
.page-forum-entry-wrapper .edit-wrapper input[type=text],
        .page-forum-entry-wrapper .edit-wrapper textarea {
            background: var(--color-ultraLight);
            border: 1px solid var(--color-ultraLight);
            border-radius: 20px;
            color: var(--color-reaction);
            display: block;
            height: 70px;
            margin-top: 15px;
            max-width: 100%;
            outline: none;
            padding: 6px 16px;    
            width: 100%;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 18px;

            line-height: 20px;
        }
.page-forum-entry-wrapper .edit-wrapper input[type=text]::placeholder,.page-forum-entry-wrapper .edit-wrapper textarea::placeholder{
                color: var(--color-reaction);

                font-family: 'Basis Grotesque Ia', sans-serif;

                font-style: normal;

                font-weight: 500;

                font-size: 16px;

                line-height: 22px;
            }
.page-forum-entry-wrapper .edit-wrapper input[type=text].error,.page-forum-entry-wrapper .edit-wrapper textarea.error{
                border: 1px solid red;
            }
.page-forum-entry-wrapper .edit-wrapper input[type=text]:focus, .page-forum-entry-wrapper .edit-wrapper textarea:focus {
                border-color: var(--color-reaction);
            }
.page-forum-entry-wrapper .edit-wrapper textarea {
            height: 400px;
            white-space: pre-line;
            margin-top: 10px;
            min-height: 200px;
            padding: 20px;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
@media (max-width: 766px) {
.page-forum-entry-wrapper .edit-wrapper textarea {
                max-width: 100%;
                min-width: 100%;
                padding-right: 0;
                height: 300px
        }
            }
.page-forum-entry-wrapper .tags {
        display: inline-flex;
        flex-wrap: wrap;
        margin-top: 30px;
        width: calc(100%);
    }
.page-forum-entry-wrapper .tags .btn {
            cursor: pointer;
            flex: 0 0 auto;
            margin-right: 5px;
            margin-top: 10px;
        }
.page-forum-entry-wrapper .tags .btn.primary:focus {
                background: var(--color-reaction);
                color: var(--color-white);
            }
@media (max-width: 766px) {
.page-forum-entry-wrapper {
        padding-bottom: 50px;
        padding: 40px 20px
}

        .page-forum-entry-wrapper h3 {
            margin-bottom: 15px;
        }
    }
@media (max-width: 941px) {
.page-forum-entry-wrapper {   
        padding: 20px;
        padding-right: 20px
}        
    }
.forum-upload-wrapper {
    margin-bottom: 20px;
    width: 100%;
}
.forum-upload-wrapper .content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        width: 100%;
    }
.forum-upload-wrapper .file-list {
        width: 100%;
    }
.forum-upload-wrapper .file-list h3 {
            margin-bottom: 15px;
        }
.forum-upload-wrapper .forum-upload-file-wrapper {
        margin-bottom: 10px;
    }
.forum-upload-wrapper .error {
        color: darkred;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.forum-upload-file-wrapper {
    background: linear-gradient(
        to right,
        var(--color-ultraLight) 50%,
        var(--color-white) 50%
    );
    background-size: 200% 100%;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow: hidden;
    width: 100%;
}
.forum-upload-file-wrapper span {
        margin-left: 5px;
    }
.forum-upload-file-wrapper.animate {
        transition: all 2s ease;
    }
.forum-upload-file-wrapper .file-name {
        padding: 12px 20px;
        flex: 6 1;
    }
.forum-upload-file-wrapper .file-name,
        .forum-upload-file-wrapper .file-name a {
            color: var(--color-reaction);
            cursor: pointer;
            text-decoration: none;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.forum-upload-file-wrapper .file-name .bold{
            text-transform: capitalize;

            font-family: 'Basis Grotesque Bold Ia', sans-serif;

            font-style: normal;

            font-weight: normal;

            font-size: 16px;

            line-height: 22px;
        }
@media (max-width: 941px) {
            .forum-upload-file-wrapper .file-name .noMobile{
                display: none;
            }
        }
.forum-upload-file-wrapper .add-wrapper {
        cursor: pointer;
        height: 100%;
        padding: 15px 23px;
    }
.forum-upload-file-wrapper .add-wrapper img {
            transform: rotate(45deg);
        }
.forum-upload-file-wrapper .remove-wrapper {
        cursor: pointer;
        height: 100%;
        padding: 15px 23px;
    }
.forum-upload-file-wrapper .arrow-wrapper {
        background-color: var(--color-white);
        height: 100%;
        padding: 15px 23px;
    }
.forum-upload-file-wrapper input[type=file] {
        display: none;
    }
.page-front-user-wrapper {
    background: var(--color-white);
    border-radius: 30px;
}
.page-front-user-wrapper .double-card {
        border-radius: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
@media (max-width: 941px) {
.page-front-user-wrapper .double-card {
            justify-content: space-around
    }
        }
.page-front-user-wrapper .user-wrapper {
        background: var(--color-whiteIsh);
        border: 3px solid var(--color-whiteIsh);
        border-radius: 20px;
        max-width: 412px;
        padding: 35px;
        width: 50%;
    }
.page-front-user-wrapper .user-wrapper:hover {
            background: var(--color-white);
        }
@media (max-width: 941px) {
.page-front-user-wrapper .user-wrapper {
            max-width: 366px;
            width: 100%
    }
        }
@media(max-width: 787px) {
.page-front-user-wrapper .user-wrapper {
            max-width: 350px
    }
        }
@media (max-width: 766px) {
.page-front-user-wrapper .user-wrapper {
            padding: 20px 20px 20px 20px
    }
        }
.page-front-user-wrapper .dialog-wrapper {
        width: 50%;
        max-width: 406px;
    }
@media (max-width: 941px) {
.page-front-user-wrapper .dialog-wrapper {
            width: 100%;
            max-width: 366px
    }
        }
@media(max-width: 787px) {
.page-front-user-wrapper .dialog-wrapper {
            max-width: 350px
    }
        }
@media (max-width: 766px) {
.page-front-user-wrapper .dialog-wrapper {
            width: calc( 100% );
            margin: 0 auto
    }
        }
.page-front-user-wrapper .tools-wrapper h2 {
            color: var(--color-dark);
            margin-bottom: 0;
            margin-top: 45px;
            
            font-family: 'Basis Grotesque Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: 500;
            
            font-size: 24px;
            
            line-height: 30px;
        }
@media (max-width: 941px) {
.page-front-user-wrapper .tools-wrapper h2 {
                margin-left: 20px
        }
            }
/* GreetingToast */
.page-front-user-wrapper .greeting-toast {
        align-content: flex-start;
        align-items: center;
        background-color: var(--color-white);
        border: 3px solid rgba(231, 231, 231, 0.3);
        border-radius: 20px;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 35px;
        max-width: 840px;
        position: relative;
        padding: 42px;
        width: 100%;
    }
.page-front-user-wrapper .greeting-toast:hover {
            background-color: var(--color-ultraLight);
        }
.page-front-user-wrapper .greeting-toast .close {
            cursor: pointer;
            position: absolute;
            right: 25px;
            top: 25px;
        }
.page-front-user-wrapper .greeting-toast .close svg {
                height: 20px;
                width: 20px;
            }
.page-front-user-wrapper .greeting-toast .image-wrapper {
            align-items: center;
            display: flex;
            flex: 0 1 auto;
            height: 90px;
            justify-content: center;
            margin-left: 16px;
            margin-right: 50px;
        }
.page-front-user-wrapper .greeting-toast .image-wrapper img {
                height: 100%;
                max-height: 90px;
            }
@media (max-width: 941px) {
.page-front-user-wrapper .greeting-toast .image-wrapper {
                display: none
        }
            }
.page-front-user-wrapper .greeting-toast .text {
            max-width: 440px;
        }
.page-front-user-wrapper .greeting-toast .text div,
            .page-front-user-wrapper .greeting-toast .text abbr {
                font-family: 'Basis Grotesque Ia', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                font-weight: 400;
            }
.page-front-user-wrapper .greeting-toast .text abbr[title] {
                text-decoration: none;
            }
@media (min-width: 943px) {
                .page-front-user-wrapper .greeting-toast .text div {
                    display: inline;
                    margin-right: 5px;
                }
            }
@media (max-width: 941px) {
                .page-front-user-wrapper .greeting-toast .text br {
                    display: none;
                }
            }
@media (max-width: 766px) {
.page-front-user-wrapper .greeting-toast {
            display: none
    }
        }
/* ActorSlider */
.page-front-user-wrapper .actor-slider {
        background-color: var(--color-whiteIsh);
        border: 1px solid var(--color-greyOutline);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        padding: 30px 25px;
        width: 100%;
    }
.page-front-user-wrapper .actor-slider h2 {
            max-width: 50%;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 24px;

            line-height: 30px;
        }
.page-front-user-wrapper .actor-slider p {
            margin-top: 0;
            margin-bottom: 0;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.page-front-user-wrapper .actor-slider > a {
            margin-top: 17.5px;
            margin-bottom: 0;
            text-decoration: underline;
            color: var(--color-reaction);
            
            font-family: 'Basis Grotesque Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: 500;
            
            font-size: 15px;
            
            line-height: 19px;
        }
.page-front-user-wrapper .actor-slider .top {
            display: flex;
            justify-content: space-between;
        }
.page-front-user-wrapper .actor-slider .top a {
                color: var(--color-reaction);
            }
.page-front-user-wrapper .actor-slider .top a:not(:hover) {
                text-decoration: none;
            }
.page-front-user-wrapper .actor-slider .top svg {
                transform: translateY(25%);
            }
/* CourseList */
.page-front-user-wrapper .courses-list > h2 {
            color: var(--color-dark);
            margin-top: 45px;
            margin-bottom: 25px;
            
            font-family: 'Basis Grotesque Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: 500;
            
            font-size: 24px;
            
            line-height: 30px;
        }
@media (max-width: 941px) {
.page-front-user-wrapper .courses-list > h2 {
                margin-left: 20px
        }
            }
@media (max-width: 766px) {
.page-front-user-wrapper .courses-list > h2 {
                margin-left: 20px
        }
            }
.page-front-user-wrapper .courses-list .top {
            margin-top: 30px;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
        }
.page-front-user-wrapper .courses-list .top h3,
            .page-front-user-wrapper .courses-list .top a {
                color: var(--color-reaction);
            }
.page-front-user-wrapper .courses-list .top h3 {
                padding-left: 10px;

                font-family: 'Basis Grotesque Bold Ia', sans-serif;

                font-style: normal;

                font-weight: normal;

                font-size: 16px;

                line-height: 22px;
            }
@media (min-width: 768px) {
.page-front-user-wrapper .courses-list .top {
                margin-top: 75px;
                padding-right: 35px
        }

                .page-front-user-wrapper .courses-list .top h3 {
                    padding-left: 0px;
                }
            }
@media (max-width: 941px) {
                .page-front-user-wrapper .courses-list .top h3 {
                    padding-left: 20px;
                }    
            }
@media (max-width: 766px) {
.page-front-user-wrapper .courses-list .top {
                padding: 20px
        }
            }
.page-front-user-wrapper .courses-list .courses {
            align-items: flex-start;
            align-content: flex-start;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            list-style: none;
            margin: 0 0 20px 0;
            padding: 0 0 0 0px;
            width: 100%;
        }
@media (max-width: 766px) {
.page-front-user-wrapper .courses-list .courses {
                flex-direction: column
        }
            }
.page-front-user-wrapper .courses-list .course {
            align-self: stretch;
            background-color: var(--color-ultraLight);
            border-radius: 20px;
            flex: 0 1 auto;
            max-width: 50%;
        }
.page-front-user-wrapper .courses-list .course:hover{
                    background-color: var(--color-white);
            }
.page-front-user-wrapper .courses-list .course a {
                color: var(--color-dark);
                display: flex;
                flex-direction: column;
                margin-top: 15px;
                padding: 20px 25px;
                text-decoration: none;
            }
.page-front-user-wrapper .courses-list .course a span:nth-child(2){
                    margin-top: 10px;
                }
.page-front-user-wrapper .courses-list .course a span:nth-child(3){
                    opacity: 0.60;
                }
@media (min-width: 768px) {
.page-front-user-wrapper .courses-list .course {
                border: 1px solid var(--color-ultraLight);
                max-width: calc(50% - 8px)
        }
                
                .page-front-user-wrapper .courses-list .course:nth-child(2n + 1){
                    margin-right: 15px;
                }

                .page-front-user-wrapper .courses-list .course span:first-child{
                    margin-bottom: 15px;
                }

                .page-front-user-wrapper .courses-list .course span{
                    padding-right: 15px;
                    text-decoration: none;
                    color: var(--color-dark);

                    font-family: 'Basis Grotesque Ia', sans-serif;

                    font-style: normal;

                    font-weight: 500;

                    font-size: 16px;

                    line-height: 22px;
                }
                
            }
@media (max-width: 941px) {
.page-front-user-wrapper .courses-list .course {
                max-width: calc(50% - 8px)
        }

                .page-front-user-wrapper .courses-list .course:nth-child(2n + 1){
                    margin-right: 15px;
                }
            }
@media (max-width: 766px) {
.page-front-user-wrapper .courses-list .course {
                margin: 10px 20px;    
                max-width: calc(100% - 8px)
        }
            }
/* DialogLatest */
.page-front-user-wrapper .dialog-latest {
        background-color: var(--color-reaction);
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding-left: 25px;
        padding-top: 36px;
        width: 100%;
    }
.page-front-user-wrapper .dialog-latest .top {
            padding: 0 25px;
        }
.page-front-user-wrapper .dialog-latest .top h2 {
                color: var(--color-white);
            }
.page-front-user-wrapper .dialog-latest .top h2:nth-child(2) {
                opacity: 85%;
            }
.page-front-user-wrapper .dialog-latest .bottom {
            align-items: center;
            color: var(--color-white);
            display: flex;
            justify-content: space-between;
            justify-self: flex-end;
            margin-bottom: 35px;
            padding: 0 25px;
            text-align: center;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.page-front-user-wrapper .dialog-latest .bottom a.btn {
                background-color: var(--color-darkReaction);
                color: var(--color-white);
                text-decoration: none !important;
            }
.page-front-user-wrapper .dialog-latest .bottom a.btn:hover {
                    background-color:var(--color-whiteIsh);
                    color: var(--color-darkReaction);
                }
.page-front-user-wrapper .dialog-latest .dialogs {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 0 25px;
            margin-top: 25px;
        }
.page-front-user-wrapper .dialog-latest .dialog {
            display: flex;
        }
.page-front-user-wrapper .dialog-latest .dialog a {
                border-radius: 10px;
                display: flex;
                padding: 15px;
                width: 100%;
            }
.page-front-user-wrapper .dialog-latest .dialog a:hover {
                    background-color: var(--color-reaction);
                    text-decoration: none;
                }
.page-front-user-wrapper .dialog-latest .dialog .image {
                border: 1px solid white;
                flex: 0 0 auto;
                margin-right: 15px;
            }
.page-front-user-wrapper .dialog-latest .dialog .image,
                .page-front-user-wrapper .dialog-latest .dialog .image > div {
                    border-radius: 50%;
                    display: flex;
                    height: 50px;
                    overflow: hidden;
                    position: relative;
                    top: -1px;
                    width: 50px;
                }
.page-front-user-wrapper .dialog-latest .dialog .image.click, .page-front-user-wrapper .dialog-latest .dialog .image > div.click {
                        cursor: pointer;
                    }
.page-front-user-wrapper .dialog-latest .dialog .image > div {
                    border: 4px solid transparent;
                }
.page-front-user-wrapper .dialog-latest .dialog .image img {
                    border-radius: 50%;
                    margin: auto;
                    width: 100%;
                }
.page-front-user-wrapper .dialog-latest .dialog .text {
                color: var(--color-white);
                display: flex;
                flex: 4 1;
                flex-direction: column;
                padding-right: 10px;

                font-family: 'Basis Grotesque Ia', sans-serif;

                font-style: normal;

                font-weight: 500;

                font-size: 16px;

                line-height: 22px;
            }
.page-front-user-wrapper .dialog-latest .dialog .text span:nth-child(2) {
                    opacity: 85%;
                }
@media (max-width: 941px) {
.page-front-user-wrapper {
        padding-bottom: 50px;       
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 30px
}
    }
/*
/ Globally used components
*/
.tools {
    background-color: var(--color-light);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.tools > a {
        text-decoration: none;
    }
@media (min-width: 768px) {
.tools {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            background-color: unset;
            margin-top: 20px;
            padding-right: 30px
}
    }
@media (max-width: 941px) {
.tools {
        padding: 0 20px
}
        
    }
@media (max-width: 766px) {
.tools {
        margin: 40px 20px 20px;
        width: calc(100% - 40px)
}

        .tools.altMobile {
            background-color: var(--color-white);
            margin: 40px 0;
            width: 100%;
        }
    }
.tool {
    border-bottom: 1px solid var(--color-ultraLight);
    width: 50%;
    max-width: 355px;
}
.tool > a {
        border-radius: 30px;
        flex-direction: column;
        padding: 20px 20px 15px 0px;
        text-decoration: none;
    }
.tool > a p {
            margin-top: 5px;
            margin-bottom: 20px;
        }
.tool > a p span,
        .tool > a p {
            transition: color 0.4s ease-out;
        }
.tool > a:hover p span,
            .tool > a:hover p {
                color: var(--color-reaction);
            }
.tool > a > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
.tool > a > div:last-child {
            border-bottom: none;
        }
.tool > a > div > div {
            color: var(--color-reaction);
        }
.tool > a > div > div:not(:hover) {
                text-decoration: none;
            }
.tool .alt-mobile {
        display: flex;
        justify-content: space-between;
        align-content: space-between;
        align-items: space-between;
        padding: 25px 0;
    }
.tool .alt-mobile a {
            text-decoration: none;

            font-family: 'Basis Grotesque Bold Ia', sans-serif;

            font-style: normal;

            font-weight: normal;

            font-size: 16px;

            line-height: 22px;
        }
@media (min-width: 768px) {
.tool .alt-mobile {
           display: none
    }
        }
.tool .mobile {
        align-content: space-between;
        align-items: space-between;
        display: flex;
        justify-content: space-between;
        padding: 25px 25px;
    }
.tool .mobile a {
            text-decoration: none;

            font-family: 'Basis Grotesque Bold Ia', sans-serif;

            font-style: normal;

            font-weight: normal;

            font-size: 16px;

            line-height: 22px;
        }
.tool .mobile svg path {
            stroke: var(--color-reaction);
        }
@media (min-width: 768px) {
.tool .mobile {
            display: none
    }
        }
.tool .desktop {
        flex: 0 1 auto;
        display: flex;
        padding-top: 15px;
    }
.tool .desktop h3 {
            margin-bottom: 2px;
        }
.tool .desktop > div {
            font-family: 'Basis Grotesque Bold Ia', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
.tool .desktop p {
            color: var(--color-dark);
            max-width: 300px;
            
            font-family: 'Basis Grotesque Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: 500;
            
            font-size: 16px;
            
            line-height: 22px;
        }
.tool .desktop p span {
                color: var(--color-dark);
                text-decoration: underline;
            }
@media (max-width: 766px) {
.tool .desktop {
            display: none
    }
        }
@media (max-width: 941px) {
.tool .desktop {
            min-width: 325px
    }
        }
@media (max-width: 766px) {
.tool {
        border-bottom: 3px solid var(--color-white);
        max-width: unset;
        width: 100%
}

        .tool.altMobile {
            border-bottom: 1px solid var(--color-greyOutline);
        }

            .tool.altMobile:first-child {
                border-top: 1px solid var(--color-greyOutline);
            }
    }
.page-landing-wrapper {
    background-color: var(--color-greyBackground);
    width: 100%;
}
.page-landing-wrapper .welcome {
        position: relative;
        z-index: 1;
    }
.page-landing-wrapper .grey-background,
    .page-landing-wrapper .grey-overlay {
        position: fixed;
        top: 0; left: 0;
        width: 100vw;
        height: 100%;
        background-color: var(--color-greyBackground);
        z-index: -1;
    }
.page-landing-wrapper .grey-overlay {
        z-index: 9999;
    }
.page-landing-wrapper .content-wrapper {
        border-radius: 30px;
        display: flex;
        margin: 0 auto;
        max-width: 1240px;
        padding-top: 10px;
    }
@media (min-width: 943px) {
.page-landing-wrapper .content-wrapper {
            padding-top: 0;
            justify-content: space-between;
            background-color: white;
            margin: 40px auto 140px
    }    
        }
@media (max-width: 941px) {
.page-landing-wrapper .content-wrapper {
            flex-direction: column
    }
        }
.page-landing-wrapper .inner-content-wrapper {
        background-color: var(--color-white);
        flex: 1 1;
        margin: 0 auto;
        padding: 30px 0 30px 0px;
    }
@media (min-width: 943px) {
.page-landing-wrapper .inner-content-wrapper {       
            padding: 80px 0 0 111px;
            border-radius: 20px 0px 0px 20px;
            max-width: 620px
    }        
        }
@media (max-width: 941px) {
.page-landing-wrapper .inner-content-wrapper {
            border-radius: 20px;
            padding: 40px 30px;
            width: 100%
    }
        }
.page-landing-wrapper .image-wrapper {
        flex: 1 1;
        margin-top: -50px;
        overflow: hidden;
    }
.page-landing-wrapper .image-wrapper img {
            width: 100%;
        }
.page-landing-wrapper .image-wrapper img:nth-child(2) {
                display: none;
            }
@media (min-width: 943px) {
.page-landing-wrapper .image-wrapper {
            border-radius: 0 20px 20px 0;
            margin-top: 0;
            max-width: 650px;
            max-height: 650px
    }
                .page-landing-wrapper .image-wrapper img:nth-child(1) {
                    display: none;
                }

                .page-landing-wrapper .image-wrapper img:nth-child(2) {
                    width: 100%;
                    height: 100%;
                    display: initial;
                }
        }
@media (max-width: 941px) {
.page-landing-wrapper .image-wrapper {
            border-radius: 0px 20px 20px 20px;
            margin-top: -20px
    }
        }
.page-landing-wrapper .login-hero-wrapper h2 {
            margin-top: 35px;
            max-width: 625px;
            
            font-family: 'Basis Grotesque Bold Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: normal;
            
            font-size: 27px;
            
            line-height: 34px;
        }
@media (min-width: 943px) {
.page-landing-wrapper .login-hero-wrapper h2 {
                color: var(--color-dark);     
                max-width: 425px
        } 
            }
.page-landing-wrapper .login-hero-wrapper .double-buttons {
            margin-top: 35px;
            position: relative;
            z-index: 1;
        }
@media (min-width: 943px) {
.page-landing-wrapper .login-hero-wrapper .double-buttons {
                margin-top: 75px;
                margin-bottom: 150px
        }
            }
.page-landing-wrapper .login-hero-wrapper p {
            max-width: 360px;
        }
@media (max-width: 941px) {
.page-landing-wrapper .login-hero-wrapper p {
                max-width: 335px
        }
            }
.page-reset-password-wrapper {
    background: white;
    border-radius: 32px;
    padding: 30px 32px;
    width: 100%;
}
.page-reset-password-wrapper h3 {
        display: flex;
        align-items: center;
    }
.page-reset-password-wrapper h3 img {
            width: 20px;
            margin-right: 15px;
        }
.page-reset-password-wrapper .reset-password-fields {
        padding: 60px 0;
    }
.page-reset-password-wrapper input {
        width: 100%;
        display: block;
        border: 0;
        padding: 6px 0;
        border-bottom: 1px solid var(--color-greyOutline);
        margin-bottom: 20px;
        outline: 0;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 16px;

        line-height: 22px;
    }
.page-reset-password-wrapper input ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: var(--color-reaction);
        }
.page-reset-password-wrapper input:focus {
            border-color: var(--color-reaction);
        }
@media (max-width: 941px) {
.page-other-actors-wrapper {
        padding: 20px;
        background-color: white;
        border-radius: 30px
}
    }
.page-partner-wrapper {
    background: var(--color-white);
    border-radius: 32px;
}
.page-partner-wrapper h1 {
        margin-bottom: 30px;
    }
.page-partner-wrapper h2 {
        color: var(--color-dark);
    }
@media (max-width: 941px) {
.page-partner-wrapper {
        padding: 20px
}
    }
@media (max-width: 766px) {
.page-partner-wrapper {
        padding-right: 40px
}
    }
@media (min-width: 768px) {
.page-partner-wrapper .partner-info {
            display: flex
    }
        }
.page-partner-wrapper .partner-branding img {
            margin: 0 auto;
            max-height: 100px;
            width: auto;
            max-width: 90%;
        }
@media (min-width: 768px) {
.page-partner-wrapper .partner-branding {
            align-items: center;
            border: 1px solid var(--color-greyBackground);
            border-radius: 20px;
            display: flex;
            flex: 1 1;
            margin-right: 20px;
            padding: 32px
    }
    
            .page-partner-wrapper .partner-branding h1 {
                margin-bottom: 0 !important;
            }
        }
@media (max-width: 766px) {
.page-partner-wrapper .partner-branding {
            display: flex;
            width: 100%
    }
            
            .page-partner-wrapper .partner-branding img {
                margin: 0 auto;
            }
        }
@media (min-width: 768px) {
.page-partner-wrapper .contact-card {
            min-height: 200px;
            flex: 1 1;
            margin: 0
    }
                .page-partner-wrapper .contact-card.small >div,
                .page-partner-wrapper .contact-card.small img {
                    max-height: 80px;
                }

                .page-partner-wrapper .contact-card.small > div{
                    margin-left: 25px;
                }
        }
@media (max-width: 766px) {
.page-partner-wrapper .contact-card {
            margin-bottom: 30px;
            margin-left: 0;
            width: 100%
    }
        }
.page-partner-wrapper .innovation-steps {
        margin-bottom: 70px;
    }
@media (min-width: 768px) {
.page-partner-wrapper .innovation-steps {
            background: var(--color-ultraLight);
            border-radius: 20px;
            margin: 40px 0 60px;
            max-width: 100%;
            padding: 60px 100px
    }

            .page-partner-wrapper .innovation-steps p {
                margin: 40px 0;
            }

            .page-partner-wrapper .innovation-steps h2 {
                max-width: 500px;

                font-family: 'Basis Grotesque Bold Ia', sans-serif;

                font-style: normal;

                font-weight: normal;

                font-size: 24px;

                line-height: 28px;
            }
        }
.page-partner-wrapper .actor-tools h2 {
            margin-bottom: 0px;

            font-family: 'Basis Grotesque Bold Ia', sans-serif;

            font-style: normal;

            font-weight: normal;

            font-size: 24px;

            line-height: 28px;
        }
.page-partner-wrapper .actor-tools .accordion {
            border-top: 0;
        }
@media (min-width: 768px) {
.page-partner-wrapper .actor-tools .accordion {
                display: none
        }
            }
.page-partner-wrapper .actor-tools-desktop {
        display: flex;
        margin-bottom: 100px;
        padding-top: 40px;
    }
@media (max-width: 766px) {
.page-partner-wrapper .actor-tools-desktop {
            display: none
    }
        }
.page-partner-wrapper .half-width {
        flex: 1 1;
    }
.page-partner-wrapper .half-width h3 {
            font-family: 'Basis Grotesque Bold Ia', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
.page-partner-wrapper .strategies-wrapper {
        background: var(--color-ultraLight);
        border-radius: 20px;
        padding: 80px 100px;
    }
.page-partner-wrapper .strategies-wrapper h2 {
            margin-bottom: 0;
        }
@media (max-width: 766px) {
.page-partner-wrapper .strategies-wrapper {
            display: none
    }
        }
.page-partner-wrapper .add-member-overlay {
        background-color: rgba(117, 117, 117, 0.5);
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        z-index: 9999999;
        width: 100vw;
    }
@media (max-width: 941px) {
.page-partner-wrapper .add-member-overlay {
            background-color: var(--color-white)
    }
        }
.page-partner-wrapper .add-member-wrapper {
        align-items: flex-start;
        background-color:  var(--color-white);
        border-radius: 18px;
        display: flex;
        flex-direction: column;
        margin-left: 10%;
        max-width: 840px;
        padding: 55px;
        padding-left: 150px;
        position: absolute;
        top: 25%;
        z-index: 999;
        width: 80%;
    }
.page-partner-wrapper .add-member-wrapper p {
            max-width: 400px;
            margin-bottom: 0;
        }
@media (max-width: 941px) {
.page-partner-wrapper .add-member-wrapper {
            position: relative;
            padding: 20px;
            margin: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%
    }
        }
.page-partner-wrapper .add-member-wrapper .close {
            background: transparent;
            border: 0;
            cursor: pointer;
            position: absolute;
            right: 150px;
            top: 60px;
        }
@media (max-width: 941px) {
.page-partner-wrapper .add-member-wrapper .close {
                top: 20px;
                right: 20px
        }
            }
.page-partner-wrapper .add-member-wrapper .add-member-fields {
            margin-top: 30px;
            width: 100%;
        }
.page-partner-wrapper .add-member-wrapper .add-member-fields > span {
                font-family: 'Basis Grotesque Bold Ia', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 19px;
            }
.page-partner-wrapper .add-member-wrapper .add-member-fields > input {
                padding-left: 16px;
            }
.page-partner-wrapper .add-member-wrapper input {
            background: var(--color-ultraLight);
            border: 1px solid transparent;
            border-radius: 20px;
            display: block;
            height: 50px;
            margin-bottom: 10px;
            margin-top: 10px;
            max-width: 520px;
            padding: 6px 6px 6px 16px;
            width: 100%;
            outline: none;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.page-partner-wrapper .add-member-wrapper input:focus {
                border: 1px solid var(--color-reaction);
            }
.page-partner-wrapper .team-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
@media (min-width: 768px) {
.page-partner-wrapper .team-wrapper {
            /* flex-direction: column-reverse; */
    }
        }
.page-partner-wrapper .team-wrapper .btn {
            margin-top: 20px;
        }
@media (min-width: 768px) {
.page-partner-wrapper .team-wrapper .btn {
                margin-top: 0
        }
            }
.page-partner-wrapper .team-wrapper .team-members {
            margin: 20px 0;
            width: 100%;
            padding-right: 20px;
        }
.page-partner-wrapper .procurement-wrapper {
        margin-top: 30px;
    }
@media (min-width: 768px) {
.page-partner-wrapper .procurement-wrapper {
            margin-top: 20px
    }
        }
.page-partner-wrapper .strategies-intro {
        margin: 30px 0 40px;
        max-width: 380px;
    }
/*
/ Globally used components
*/
.team-member {
    border-top: 2px solid var(--color-ultraLight);
    display: flex;
    margin-bottom: 5px;
    padding: 5px 0;
    position: relative;
}
.team-member:last-child {
        border-bottom: 2px solid var(--color-ultraLight);
    }
.team-member .team-member-text {
        flex: 5 1;
        font-size: 14px;
        line-height: 18px;
    }
.team-member .team-member-text .name {
            color: var(--color-reaction);
            font-weight: 500;
        }
.team-member .team-member-text .email {
            color: var(--color-reaction);
            margin-top: 10px;
            text-decoration: underline;
        }
.team-member .team-member-image {
        flex: 0 1 auto;
        border: 1px solid var(--color-white);
        margin-right: 22px;
    }
.team-member .team-member-image,
        .team-member .team-member-image > a {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            position: relative;
            background-color: var(--color-greyBackground);
        }
.team-member .team-member-image.click, .team-member .team-member-image > a.click {
                cursor: pointer;
            }
.team-member .team-member-image > a {
            border: 4px solid transparent;
        }
.team-member .team-member-image img {
            width: 100%;
            margin: auto;
        }
.team-member .team-member-delete {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        position: absolute;
        top: 32px;
        right: 3px;
        cursor: pointer;
        color: var(--color-grey);
        padding: 2px 6px;
    }
.team-member .team-member-delete:hover,
        .team-member .team-member-delete:active {
            color: var(--color-otherGrey);
        }
@media (min-width: 768px) {
.team-member {
        margin-bottom: 0;
        padding: 15px 0;
        width: 100%
}
    }
.procurement {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.procurement .circle {
        background: var(--color-reaction);
        border-radius: 50%;
        display: block;
        height: 15px;
        margin-right: 9px;
        margin-top: 4px;
        min-width: 15px;
        width: 15px;
    }
@media (min-width: 768px) {
.procurement .circle {
            display: none
    }
        }
.procurement .date {
        text-transform: capitalize;
    }
@media (max-width: 766px) {
.procurement .date {
            display: none
    }
        }
.procurement .title {
        align-items: center;
        color: var(--color-reaction);
        display: flex;
        justify-content: space-between;

        font-family: 'Basis Grotesque Bold Ia', sans-serif;

        font-style: normal;

        font-weight: normal;

        font-size: 16px;

        line-height: 22px;
        font-weight: 500;
    }
.procurement .title a {
            color: inherit;
            text-decoration: none;
        }
.procurement .title a:hover {
                text-decoration: underline;
            }
@media (max-width: 941px) {
.procurement .title {
            max-width: 325px
    }
        }
.procurement .ongoing {
        border: 1px solid var(--color-reaction);
        color: var(--color-reaction);
        border-radius: 4px;
        padding: 6px 8px;     
        position: absolute;
        right: 0;
        top: 25px;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 12px;

        line-height: 20px;
    }
@media (max-width: 766px) {
.procurement .ongoing {
            display: none
    }
        }
.procurement .done {
        border-radius: 4px;
        background: var(--color-ultraLight);
        color: var(--color-dark);
        padding: 8px;
        position: absolute;
        right: 0;
        top: 25px;

        font-family: 'Basis Grotesque Ia', sans-serif;

        font-style: normal;

        font-weight: 500;

        font-size: 12px;

        line-height: 20px;
    }
@media (max-width: 766px) {
.procurement .done {
            display: none
    }
        }
@media (min-width: 768px) {
.procurement {
        height: 80px;
        margin: 0;
        padding: 15px 0;
        border-top: 2px solid var(--color-ultraLight)
}
        
        
        .procurement:nth-child(4) {
            border-bottom: 2px solid var(--color-ultraLight);
        }
    }
@media (max-width: 766px) {
.procurement {
        flex-direction: row;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: flex-start
}
    }
.page-single-course-wrapper {
    background: var(--color-white);
    border-radius: 30px;
    padding-bottom: 40px;
    padding-right: 80px;
    position: relative;
}
.page-single-course-wrapper .btn {
        flex: 0 1;
        padding: 8px 15px;
        margin-top: 30px;
    }
.page-single-course-wrapper .btn span {
            height: 100%;
            margin-left: 4px;
        }
.page-single-course-wrapper .btn span svg {
                height: 100%;
                transform: translateY(2px);
                margin: 0;
            }
.page-single-course-wrapper .course-info {
        align-items: flex-start;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        max-width: 700px;
        width: 100%;
    }
.page-single-course-wrapper .course-info h1 {
            color: var(--color-dark);
            margin-bottom: 12px;
        }
.page-single-course-wrapper .course-info .date {
            background-color: var(--color-greyBackground);
            border-radius: 10px;
            color: var(--color-dark);
            padding: 6px 12px;
            position: absolute;
            right: 0;
            top: 0;            
        }
.page-single-course-wrapper .course-info .date,
            .page-single-course-wrapper .course-info .date span {
                text-transform: capitalize;

                font-family: 'Basis Grotesque Bold Ia', sans-serif;

                font-style: normal;

                font-weight: normal;

                font-size: 16px;

                line-height: 22px;
            }
@media (max-width: 941px) {
.page-single-course-wrapper .course-info .date {
                display: none            
        }
            }
.page-single-course-wrapper .course-info .time {
            color: var(--color-dark);
            display: flex;
            flex-direction: column;
            line-height: 22px;
        }
.page-single-course-wrapper .course-info .time span {
                margin-right: 5px;
            }
.page-single-course-wrapper .course-info .time span:nth-child(1) {
                    text-transform: capitalize;
                }
.page-single-course-wrapper .course-info .where {
            line-height: 22px;
            color: var(--color-reaction);
        }
.page-single-course-wrapper .course-text {
        align-items: flex-start;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        padding: 10px 0;
        width: 100%;
    }
.page-single-course-wrapper .course-text ul {
            list-style: none;
            position: relative;
            left: -18px;
        }
.page-single-course-wrapper .course-text ul li:before {
                content: '\\2022';
                color: var(--color-reaction);
                display: inline-block;
                left: 18px;
                position: absolute;
                width: 16px;

                font-family: 'Basis Grotesque Bold Ia', sans-serif;

                font-style: normal;

                font-weight: normal;

                font-size: 32px;

                line-height: 36px;
            }
.page-single-course-wrapper .course-text .ingress,
            .page-single-course-wrapper .course-text .ingress p {
                font-family: 'Basis Grotesque Bold Ia', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
            }
.page-single-course-wrapper .course-speakers {
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        padding: 10px 0;
        width: 100%;
    }
.page-single-course-wrapper .course-speakers h3 {
            color: var(--color-dark);
            font-weight: 500px;
            margin-bottom: 12px;
        }
.page-single-course-wrapper .course-files {
        width: 100%;
    }
.page-single-course-wrapper .course-files h2 {
            color: var(--color-dark);
            
            font-family: 'Basis Grotesque Bold Ia', sans-serif;
            
            font-style: normal;
            
            font-weight: normal;
            
            font-size: 16px;
            
            line-height: 22px;
        }
.page-single-course-wrapper .course-files .file-wrapper {
            background-color: var(--color-ultraLight);
            border: 1px solid #e3e3e3;
            border-radius: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;
            overflow: hidden;
            width: 100%;
        }
.page-single-course-wrapper .course-files .file-wrapper span {
                margin-left: 5px;
            }
.page-single-course-wrapper .course-files .name {
            padding: 12px 20px;
            flex: 6 1;           
        }
.page-single-course-wrapper .course-files .name,
            .page-single-course-wrapper .course-files .name a {
                color: var(--color-reaction);
                cursor: pointer;
                text-decoration: none;

                font-family: 'Basis Grotesque Bold Ia', sans-serif;

                font-style: normal;

                font-weight: normal;

                font-size: 16px;

                line-height: 22px;
            }
.page-single-course-wrapper .course-files .arrow {
            height: 100%;
            padding: 15px 23px;
        }
.page-single-course-wrapper .course-files .arrow img {
                transform: translateX(-2px);
            }
.page-single-course-wrapper .course-files .list {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            padding: 10px 0;
            width: 100%;
        }
@media (min-width: 943px) {
.page-single-course-wrapper {
        max-width: 650px
}
    }
@media (max-width: 941px) {
.page-single-course-wrapper {
        padding: 20px
}
    }
.page-single-partner-wrapper {
    background: var(--color-white);
    border-radius: 32px;
}
.page-single-partner-wrapper h1 {
        margin-bottom: 30px;
    }
.page-single-partner-wrapper h2 {
        color: var(--color-dark);
    }
@media (max-width: 941px) {
.page-single-partner-wrapper {
        padding: 20px
}
    }
@media (max-width: 766px) {
.page-single-partner-wrapper {
        padding-right: 40px
}
    }
@media (min-width: 768px) {
.page-single-partner-wrapper .partner-info {
            display: flex
    }
        }
.page-single-partner-wrapper .partner-branding img {
            margin: 0 auto;
            max-height: 100px;
            width: auto;
            max-width: 90%;
        }
@media (min-width: 768px) {
.page-single-partner-wrapper .partner-branding {
            align-items: center;
            border: 1px solid var(--color-greyBackground);
            border-radius: 20px;
            display: flex;
            flex: 1 1;
            margin-right: 20px;
            padding: 32px
    }
    
            .page-single-partner-wrapper .partner-branding h1 {
                margin-bottom: 0 !important;
            }
        }
@media (max-width: 766px) {
.page-single-partner-wrapper .partner-branding {
            display: flex;
            width: 100%
    }
            
            .page-single-partner-wrapper .partner-branding img{
                margin: 0 auto;
            }
        }
@media (min-width: 768px) {
.page-single-partner-wrapper .contact-card {
            min-height: 200px;
            flex: 1 1;
            margin: 0
    }
                .page-single-partner-wrapper .contact-card.small >div,
                .page-single-partner-wrapper .contact-card.small img {
                    max-height: 80px;
                }
                .page-single-partner-wrapper .contact-card.small > div {
                    margin-left: 25px;
                }
        }
@media (max-width: 766px) {
.page-single-partner-wrapper .contact-card {
            margin-bottom: 30px;
            margin-left: 0;
            width: 100%
    }
        }
.page-single-partner-wrapper .innovation-steps {
        margin-bottom: 70px;
    }
@media (min-width: 768px) {
.page-single-partner-wrapper .innovation-steps {
            background: var(--color-ultraLight);
            border-radius: 20px;
            margin: 40px 0 60px;
            max-width: 100%;
            padding: 60px 100px
    }

            .page-single-partner-wrapper .innovation-steps p {
                margin: 40px 0;
            }

            .page-single-partner-wrapper .innovation-steps h2 {
                max-width: 500px;

                font-family: 'Basis Grotesque Bold Ia', sans-serif;

                font-style: normal;

                font-weight: normal;

                font-size: 24px;

                line-height: 28px;
            }
        }
.page-single-partner-wrapper .actor-tools h2 {
            margin-bottom: 0px;

            font-family: 'Basis Grotesque Bold Ia', sans-serif;

            font-style: normal;

            font-weight: normal;

            font-size: 24px;

            line-height: 28px;
        }
.page-single-partner-wrapper .actor-tools .accordion {
            border-top: 0;
        }
@media (min-width: 768px) {
.page-single-partner-wrapper .actor-tools .accordion {
                display: none
        }
            }
.page-single-partner-wrapper .actor-tools-desktop {
        display: flex;
        margin-bottom: 100px;
        padding-top: 40px;
    }
@media (max-width: 766px) {
.page-single-partner-wrapper .actor-tools-desktop {
            display: none
    }
        }
.page-single-partner-wrapper .half-width {
        flex: 1 1;
    }
.page-single-partner-wrapper .half-width h3 {
            font-family: 'Basis Grotesque Bold Ia', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
.page-single-partner-wrapper .strategies-wrapper {
        background: var(--color-ultraLight);
        border-radius: 20px;
        padding: 80px 100px;
    }
.page-single-partner-wrapper .strategies-wrapper h2 {
            margin-bottom: 0;
        }
@media (max-width: 766px) {
.page-single-partner-wrapper .strategies-wrapper {
            display: none
    }
        }
.page-single-partner-wrapper .team-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
@media (min-width: 768px) {
.page-single-partner-wrapper .team-wrapper {
            flex-direction: column-reverse
    }
        }
.page-single-partner-wrapper .team-wrapper .btn {
            margin-top: 20px;
        }
@media (min-width: 768px) {
.page-single-partner-wrapper .team-wrapper .btn {
                margin-top: 0
        }
            }
.page-single-partner-wrapper .team-wrapper .team-members {
            margin: 20px 0;
            width: 100%;
            padding-right: 20px;
        }
.page-single-partner-wrapper .procurement-wrapper {
        margin-top: 30px;
    }
@media (min-width: 768px) {
.page-single-partner-wrapper .procurement-wrapper {
            margin-top: 20px
    }
        }
.page-single-partner-wrapper .strategies-intro {
        margin: 30px 0 40px;
        max-width: 380px;
    }
.page-tools-wrapper {
    background-color: var(--color-white);
}
.page-tools-wrapper h1 {
        color: var(--color-dark);
        margin-bottom: 20px;
        margin-top: 0px;

        font-family: 'Basis Grotesque Bold Ia', sans-serif;

        font-style: normal;

        font-weight: normal;

        font-size: 32px;

        line-height: 36px;
    }
@media (max-width: 941px) {
.page-tools-wrapper {
        border-radius: 30px;
        margin-bottom: 50px;
        padding: 20px;
        padding-left: 0
}

        .page-tools-wrapper h1 {
            margin-left: 20px;
        } 
    }
@media (max-width: 766px) {
.page-tools-wrapper {
        padding-right: 20px;
        padding-left: 20px
}
    }
.page-url-login-wrapper {
    width: 100%;
    background-color: var(--color-greyBackground);
}
.page-url-login-wrapper .grey-background {
        position: fixed;
        top: 0; left: 0;
        width: 100vw;
        height: 100%;
        background-color: var(--color-greyBackground);
        z-index: -1;
    }
.page-url-login-wrapper .content-wrapper {
        background: var(--color-white);
        border-radius: 30px;
        padding-top: 10px;
    }
@media (min-width: 943px) {
.page-url-login-wrapper .content-wrapper {
            padding-top: 0;
            display: flex;
            flex-direction: row;
            padding-left: 60px
    }                    
        }
.page-url-login-wrapper .image {
        flex: 1 1;
        margin-top: -50px;
    }
.page-url-login-wrapper .image img {
            width: 100%;
        }
.page-url-login-wrapper .image img:nth-child(2) {
                display: none;
            }
@media (min-width: 943px) {
.page-url-login-wrapper .image {
            border-radius: 0 30px 30px 0;
            margin-top: 0;    
            overflow: hidden
    }
                .page-url-login-wrapper .image img:nth-child(1) {
                    display: none;
                }

                .page-url-login-wrapper .image img:nth-child(2){
                    width: auto;
                    height: 100%;
                    display: initial;
                }
        }
.page-url-login-wrapper .url-login-wrapper {
        background: white;
        border-radius: 32px;
        padding: 30px 32px;
        width: 100%;
    }
.page-url-login-wrapper .url-login-wrapper h3 {
            display: flex;
            align-items: center;
        }
.page-url-login-wrapper .url-login-wrapper h3 img {
                width: 20px;
                margin-right: 15px;
            }
.page-url-login-wrapper .url-login-fields {
        padding: 60px 0;
    }
.page-url-login-wrapper .url-login-fields input {
            width: 100%;
            display: block;
            border: 0;
            padding: 6px 0;
            border-bottom: 1px solid var(--color-greyOutline);
            margin-bottom: 20px;
            outline: 0;

            font-family: 'Basis Grotesque Ia', sans-serif;

            font-style: normal;

            font-weight: 500;

            font-size: 16px;

            line-height: 22px;
        }
.page-url-login-wrapper .url-login-fields input ::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: var(--color-reaction);
            }
.page-url-login-wrapper .url-login-fields input:focus {
                border-color: var(--color-reaction);
            }
@media (min-width: 768px) {
.page-url-login-wrapper {
        padding-left: 82px;
        padding-right: 82px;
        padding-top: 30px
}
    }
.double-buttons {
    margin-top: 35px;
    position: relative;
    z-index: 1;
}
.double-buttons .btn {
        align-items: center;
        display: inline-flex;
    }
.double-buttons .btn:first-child {
            margin-right: 15px;
        }
.contact-card-wrapper {
    margin-top: 65px;
}
.contact-card-wrapper h2 {
        color: var(--color-dark);
        margin-bottom: 24px;
    }
@media (max-width: 766px) {
.contact-card-wrapper {
        /* display: none; */
        margin: 20px 10px 20px 10px
}
    }
svg.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
}
svg.spinner .path {
        stroke: var(--color-reaction);
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }
@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 */
:where(html) {
  line-height: 1.15; /* 1 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
:where(h1) {
  font-size: 2em;
  margin-block-end: 0.67em;
  margin-block-start: 0.67em;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, and Safari.
 */
:where(dl, ol, ul) :where(dl, ol, ul) {
  margin-block-end: 0;
  margin-block-start: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Correct the inheritance of border color in Firefox.
 */
:where(hr) {
  box-sizing: content-box; /* 1 */
  color: inherit; /* 2 */
  height: 0; /* 1 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
:where(pre) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Safari.
 */
:where(abbr[title]) {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
:where(b, strong) {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
:where(code, kbd, samp) {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
:where(small) {
  font-size: 80%;
}
/* Tabular data
 * ========================================================================== */
/**
 * 1. Correct table border color in Chrome, Edge, and Safari.
 * 2. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */
:where(table) {
  border-color: currentColor; /* 1 */
  text-indent: 0; /* 2 */
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
:where(button, input, select) {
  margin: 0;
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
:where(button) {
  text-transform: none;
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
:where(button, input:is([type="button" i], [type="reset" i], [type="submit" i])) {
  -webkit-appearance: button;
}
/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
:where(progress) {
  vertical-align: baseline;
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
:where(select) {
  text-transform: none;
}
/**
 * Remove the margin in Firefox and Safari.
 */
:where(textarea) {
  margin: 0;
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
:where(input[type="search" i]) {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
:where(button, input:is([type="button" i], [type="color" i], [type="reset" i], [type="submit" i]))::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:where(button, input:is([type="button" i], [type="color" i], [type="reset" i], [type="submit" i]))::-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:where(:-moz-ui-invalid) {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct styles in Safari.
 */
:where(dialog) {
  background-color: white;
  border: solid;
  color: black;
  height: -moz-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
}
:where(dialog:not([open])) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
:where(summary) {
  display: list-item;
}
* {
    box-sizing: border-box;
    transition: background .4s ease, color .1s ease, border .4s ease;
}
html {
    overflow-y: scroll;
}
body {
    background: var(--color-greyBackground);
    margin: 0;
    min-height: 100vh;
}
@media(min-width: 1001px) {
body {
        background: var(--color-white)
}
    }
h1 {
    margin: 0;
    color: var(--color-reaction);
}
h2 {
    margin: 0;
    color: var(--color-reaction);
}
h3 {
    margin: 0;
}
a {
    color: var(--color-reaction);
}
.main-wrapper {
    background-color: var(--color-white);
    transition: unset;
    width: 100%;
}
.main-wrapper.greyBackground {
        background-color: var(--color-greyBackground);
        max-width: unset !important;
        width: 100%;
    }
@media (min-width: 1201px) {
.main-wrapper {
        display: flex;
        margin: 0 auto;
        max-width: 1440px
}

        .main-wrapper:not(.authToken){
            padding-left: 0;
        }
    }
@media (max-width: 941px){
.main-wrapper {
        background-color: var(--color-greyBackground);
        margin: 0 auto
}   
    }
.spacer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 140px);
}
.main-content {
    background-color: var(--color-white);
    flex: 8 1 auto;
    transition: unset;
}
.main-content.greyBackground {
        margin: 0 auto !important;
        background-color: var(--color-greyBackground);;
    }
@media (min-width: 943px) {
.main-content {
        max-width: 840px;
        min-width: 840px;
        margin: 38px 38px 0 100px
}

        .main-content:not(.authToken){
            max-width: 1440px;
        }
    }
@media (max-width: 1399px) {
.main-content {
        margin: 38px 38px 0 50px
}       
    }
@media (max-width: 941px) {
.main-content {
        background-color: var(--color-greyBackground);
        width: 100%;
        margin: 0 auto
}
    }
@media (max-width: 766px) {
.main-content {
        max-width: 375px
}
    }